import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useIdleTimer } from 'react-idle-timer';

import "./assets/sass/reset.scss";
import "./assets/sass/style.scss";

import SignIn from "./components/Authentication/SignIn";
import Layout from "./components/Layout/Layout";
import ConsentEvents from "./components/ConsentEvents/ConsentEvents";
import Users from "./components/Users/Users";
import UserSingle from "./components/Users/UserSingle";
import Settings from "./components/Settings/Settings";
import ConsentEventSingle from "./components/ConsentEvents/ConsentEventSingle";
import AuthContext from "./store/auth-context";
import ErrorPage from "./components/UI/ErrorPage";
import { SESSION_EXPIRED_TIME } from "./utils/helpers";
import UsersCEList from "./components/Users/UsersCEList";
import RevokeCE from "./components/Revoke/RevokeCE";
import DownloadPage from "./components/Download/DownloadPage";
import CEERetrievalPayment from "./components/Payment/CEERetrievalPayment";

function App() {
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  const [sessionExpired, setSessionExpired] = useState(false);
  const [eventCount, setEventCount] = useState(0);

  const onAction = () => {
    setEventCount(eventCount + 1);
  }

  useIdleTimer({
    onAction,
    timeout: SESSION_EXPIRED_TIME,
    throttle: 500
  });

  useEffect(() => {
    let interval;

    if(token && !window.location.href.includes("external")) {
      interval = setTimeout(() => {
        setSessionExpired(true);
        authCtx.removeAuthToken();
      }, SESSION_EXPIRED_TIME);
    }

    return () => {
      clearInterval(interval)
    }
  }, [eventCount, token, authCtx]);

  if (!token) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<SignIn />} />
            <Route path="consent-events/:event_id" element={<SignIn />} />
            <Route path="external/revoke" element={<RevokeCE />} />
            <Route path="external/download" element={<DownloadPage />} />
            <Route path="external/payment/:event_id" element={<CEERetrievalPayment />} />
          </Route>
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout isSessionExpired={sessionExpired} />}>
          <Route index element={<ConsentEvents />} />
          <Route
            path="consent-events/:event_id"
            element={<ConsentEventSingle />}
          />
          <Route path="users" element={<Users />} />
          <Route path="users/:user_id" element={<UserSingle />} />
          <Route path="users/:user_id/events" element={<UsersCEList />} />
          <Route path="settings" element={<Settings />} />
        </Route>
        <Route path="external/revoke" element={<RevokeCE />} />
        <Route path="external/download" element={<DownloadPage />} />
        <Route path="external/payment/:event_id" element={<CEERetrievalPayment />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
