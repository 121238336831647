import React, { useState } from "react";
import { API_TEST, getAuthToken, getRefreshToken } from "../utils/helpers";

const AuthContext = React.createContext({
  token: null,
  onLogIn: (email, password) => {},
  onLogOut: () => {},
  setAuthToken: (token, refreshToken, adminEmail, rememberMe = false) => {},
  removeAuthToken: () => {},
});

export const AuthContextProvider = (props) => {
  const [token, setToken] = useState(() => getAuthToken());
  const [refreshToken, setRefreshToken] = useState(() => getRefreshToken());

  const setAuthTokenHandler = (token, refreshToken, adminId, admin, rememberMe = false) => {
    if (rememberMe) {
      localStorage.setItem("token", token);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("adminId", adminId);
      localStorage.setItem("admin", JSON.stringify(admin));
    } else {
      sessionStorage.setItem("token", token);
      sessionStorage.setItem("refreshToken", refreshToken);
      sessionStorage.setItem("adminId", adminId);
      sessionStorage.setItem("admin", JSON.stringify(admin));
    }
    setToken(token);
    setRefreshToken(refreshToken);
  };

  const setNewTokenHandler = (token) => {
    sessionStorage.setItem("token", token);
    setToken(token);
  }

  const removeAuthTokenHandler = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("adminId");
    localStorage.removeItem("admin");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("refreshToken");
    sessionStorage.removeItem("adminId");
    sessionStorage.removeItem("admin");
  };

  const logInHandler = async (email, password, rememberMe) => {
    const responseData = {
      data: {
        id: null,
        email: null,
        auth: false
      },
      token: null,
      refreshToken: null,
      ok: false,
      error: null,
    };

    const response = await fetch(API_TEST + 'Admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        email,
        password
      }),
    });

    responseData.ok = response.ok;
    const resData = await response.json();

    if (!responseData.ok) {
      responseData.error = resData.Message;
      return responseData;
    }

    const tokenKey = resData.accessToken;
    const refreshTokenKey = resData.refreshToken;
    
    responseData.data.email = email;
    responseData.data.id = resData.id ? resData.id : null;
    responseData.data.auth = resData.is2FA ? resData.is2FA : false;
    responseData.token = tokenKey;
    responseData.refreshToken = refreshTokenKey;

    return responseData;
  };

  const logOutHandler = () => {
    removeAuthTokenHandler();
    setToken(null);
    setRefreshToken(null);
    window.location.replace("/");
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        refreshToken,
        onLogIn: logInHandler,
        onLogOut: logOutHandler,
        setAuthToken: setAuthTokenHandler,
        setNewToken: setNewTokenHandler,
        removeAuthToken: removeAuthTokenHandler,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
