import React from 'react';
import { useNavigate } from "react-router-dom";
import { getDateString, getTimeString } from "../../utils/helpers";

import iconPeaches from '../../assets/images/icon-peaches.svg';
import iconPremium from '../../assets/images/icon-premium.svg';

const UsersItem = (props) => {
  const navigate = useNavigate();
  const user = props.user;

  let subscriptionType = null;
  if(user.subscriptionType === 1) { subscriptionType = "standard"; }
  if(user.subscriptionType === 2) { subscriptionType = "premium"; }

  const userHandler = (e, userId) => {
    navigate("/users/" + userId);
  }

  return (
    <tr onClick={(e) => userHandler(e, user.id)}>
      <td>{ user.name }</td>
      <td>{ user.phoneNumber }</td>
      <td>{ user.dateOfBirth }</td>
      <td className={"user-subscription " + subscriptionType }>{subscriptionType === "premium" ? <img src={iconPremium} alt="Premium icon" /> : <img src={iconPeaches} alt="Peaches icon" />} { subscriptionType }</td>
      <td>{ user.expiresOn && getDateString(new Date(user.expiresOn)) } <span>{ user.expiresOn && getTimeString(new Date(user.expiresOn)) }</span> { user.expiresOn && "UTC" }</td>
      <td>{ user.numberOfCEs }</td>
    </tr>
  );
}

export default UsersItem;