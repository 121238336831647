import { useContext } from "react";
import { Outlet } from "react-router-dom";

import Navigation from "./Navigation";
import NotificationContext from "../../store/notification-context";
import Notification from "../UI/Notification";
import ExpiredModal from "../UI/SessionExpiredModal";
import AuthContext from "../../store/auth-context";

const Layout = (props) => {
  const notificationCtx = useContext(NotificationContext);
  const authCtx = useContext(AuthContext);

  return (
    <div className="main">
      {notificationCtx.showNotification && <Notification />}
      {props.isSessionExpired && <ExpiredModal />}
      <Navigation signOut={authCtx.onLogOut} />
      <Outlet />
    </div>
  );
};

export default Layout;
