import React from 'react';

import { Link } from "react-router-dom";
import iconArrowRight from '../../assets/images/icon-arrow-right.svg';

const ContainerTitle = (props) => {
  return (
    <div className="container-title">
      <p>{props.title}</p>
      <div className="breadcrumbs">
        { !props.parentPageLink && !props.pageLabel && <span>List</span>}
        { props.parentPageLink && <Link to={props.parentPageLink}>List</Link>}

        { props.pageLabel && <span>{props.pageLabel}</span>}

        { props.childPageTitle && <img src={iconArrowRight} alt="Right arrow icon" />}
        { props.childPageTitle && <span className="breadcrumbs-current">{props.childPageTitle}</span> }
      </div>
    </div>
  );
}

export default ContainerTitle;