import { useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import downloadBg from '../../assets/images/download-bg.png';
import Loading from '../UI/Loading';
import ErrorPage from '../UI/ErrorPage';
import { API_TEST } from '../../utils/helpers';
import RevokeSuccess from './RevokeSuccess';
import AuthContext from '../../store/auth-context';

const RevokeCE = () => {
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  const [searchParams] = useSearchParams();
  const userId = searchParams.get("uid");
  const eventId = searchParams.get("cid");
  
  const [revokeDate, setRevokeDate] = useState(null);
  const [isRevoking, setIsRevoking] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");

  if(!userId || !eventId) {
    return <ErrorPage />
  }

  const revokingHandler = () => {
    setIsRevoking(true);
    setError("");

    try {
      const revokeData = {
        userId: userId,
        consentEventId: eventId
      }
      fetch(API_TEST + `ConsentEvents/revoke`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json-patch+json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(revokeData)
      }).then((res) => {
        return res.json();
      }).then((data) => {
        if(data.Succeeded && data.Succeeded === false) {
          setIsRevoking(false);
          setError(data.Message);
          return;
        }
        setIsRevoking(false);
        setRevokeDate(data.revokedAt);
        setIsSuccess(true);
      });
    } catch(err) {
      console.log(err);
    }
  }

  return (
    <>
      { isSuccess &&  <RevokeSuccess date={revokeDate} /> }
      { !isSuccess &&
        <div className="download revoke-page">
          <div className="download-container">
            <div className="download-content-wrap">
              <div className="download-content">
                <div className="download-title">
                  <h2>Revoke Consent Event</h2>
                </div>
                <div className="revoke-content">
                  <p>Are you sure you want to revoke the specific Consent Event?</p>
                  <div className="revoke-content-date">
                    <p style={{margin: 0}}></p>
                    <p>Click the button below to proceed with revoking the consent event.</p>
                  </div>
                  <p>If you have further questions, reach out to Peach Protector Support at <a href="mailto:support@peachprotector.com" target="_top">support@peachprotector.com</a>.</p>
                </div>
                <div className="revoke-button">
                  <button className="btn btn-primary" disabled={isRevoking} onClick={revokingHandler}><span>CONFIRM REVOCATION</span></button>
                  { error && <p className="error-text">{error}</p>}
                </div>
                { isRevoking && <Loading /> }
              </div>
            </div>
            <div className="download-sidebar"><img src={downloadBg} alt="Download background" /></div>
          </div>
        </div>
      }
    </>
  );
}

export default RevokeCE;