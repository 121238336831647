export const API_TEST = process.env.REACT_APP_API_ENDPOINT;

export const ITEMS_PER_PAGE = 15;
export const COPY_DELAY = 3000;
export const SESSION_EXPIRED_TIME = 5 * 60 * 1000;

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

export const getAuthToken = () => {
  let token = null;

  if (sessionStorage.getItem("token")) token = sessionStorage.getItem("token");
  if (localStorage.getItem("token")) token = localStorage.getItem("token");

  return token;
};

export const getRefreshToken = () => {
  let token = null;

  if (sessionStorage.getItem("refreshToken")) token = sessionStorage.getItem("refreshToken");
  if (localStorage.getItem("refreshToken")) token = localStorage.getItem("refreshToken");

  return token;
};

export const getAdminData = () => {
  let adminData = null;

  if (sessionStorage.getItem("admin"))
    adminData = sessionStorage.getItem("admin");
  if (localStorage.getItem("admin"))
    adminData = localStorage.getItem("admin");

  return JSON.parse(adminData);
};

export const getAdminId = () => {
  let id = null;

  if (sessionStorage.getItem("adminId"))
    id = sessionStorage.getItem("adminId");
  if (localStorage.getItem("adminId"))
    id = localStorage.getItem("adminId");

  return id;
};

export const getDateString = (date) => {
  const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  let month = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(date);
  let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

  // if(month < 10) { month = '0' + month}
  // if(day < 10) { day = '0' + day}

  return `${month}/${day}/${year}`;
}

export const getTimeString = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();

  if(hours < 10) { hours = '0' + hours}
  if(minutes < 10) { minutes = '0' + minutes}

  return `${hours}:${minutes}`;
}