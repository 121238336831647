import React, { useEffect, useState, useContext } from 'react';
import { Link } from "react-router-dom";
import NotificationContext from '../../store/notification-context';
import ConsentEventHistory from './ConsentEventHistory';
import AuthModal from '../UI/AuthModal';
import Modal from '../UI/Modal';
import { API_TEST, copyToClipboard, getAdminData, getAdminId } from '../../utils/helpers';
import { COPY_DELAY } from '../../utils/helpers';
import { getDateString, getTimeString } from "../../utils/helpers";

import iconArchived from '../../assets/images/icon-archived.svg';
import iconEye from '../../assets/images/icon-eye.svg';
import iconLocation from '../../assets/images/icon-location-large.svg';
import iconCopy from '../../assets/images/icon-copy.svg';
import iconAccept from '../../assets/images/icon-accept.svg';
import iconCancel from '../../assets/images/icon-cancel.svg';
import iconRotate from '../../assets/images/arrows-rotate.svg';
import AuthContext from '../../store/auth-context';

const ConsentEventSingleTable = (props) => {
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  const { event, user } = props;
  const notificationCtx = useContext(NotificationContext);
  const [isAuth, setIsAuth] = useState(false);
  const [rejectingEvent, setRejectingEvent] = useState(false);
  const adminData = getAdminData();
  const adminId = getAdminId()

  // Consent Type
  let eventType = null;
  if(event.consentEvent.consentType === 1) { eventType = "Q"; }
  if(event.consentEvent.consentType === 2) { eventType = "L"; }
  if(event.consentEvent.consentType === 3) { eventType = "K"; }

  const getConsentTypeName = (consentType) => {
    if (consentType === "q") return 'Ask for Consent';
    if (consentType === "l") return 'Define limits';
    if (consentType === "k") return 'Yes/No/Maybe';
    return 'Unknown';
  };

  // Request Retrieval Status
  let eventRequest = null;
  if(event.requestRetrieval && event.requestRetrieval.status === 1) { eventRequest = 'requested'; }
  if(event.requestRetrieval && event.requestRetrieval.status === 2) { eventRequest = 'rejected'; }
  if(event.requestRetrieval && event.requestRetrieval.status === 3) { eventRequest = 'pending'; }
  if(event.requestRetrieval && event.requestRetrieval.status === 4) { eventRequest = 'confirmed'; }
  if(event.requestRetrieval && event.requestRetrieval.status === 5) { eventRequest = 'paid'; }
  if(event.requestRetrieval && event.requestRetrieval.status === 6) { eventRequest = 'retrieved'; }

  // Status
  let eventStatus = null;
  if(event.consentEvent.consentEventStatus === 1) { eventStatus = 'accepted'; }
  if(event.consentEvent.consentEventStatus === 2) { eventStatus = 'denied'; }
  if(event.consentEvent.consentEventStatus === 3) { eventStatus = 'revoked'; }

  // Note
  const [note, setNote] = useState(event.consentEvent.note);
  const [noteUpdated, setNoteUpdated] = useState(false);
  let noteCopy = event.note;

  const noteInputHandler = (e) => {
    const noteVal = e.target.value;

    setNote(noteVal);

    if(noteVal !== noteCopy) {
      setNoteUpdated(true);
    } else {
      setNoteUpdated(false);
    }
  }

  const noteSaveHandler = () => {
    props.onUpdateNote(note);
    setNoteUpdated(false);
  }

  const noteCancelHandler = () => {
    setNote(noteCopy);
    setNoteUpdated(false);
  }

  // Copy
  const [isEmailCopied, setIsEmailCopied] = useState(false);
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const copyEmailHandler = (emailText) => {
    if(isEmailCopied) return;
    setIsEmailCopied(true);
    copyToClipboard(emailText);
  }

  const copyLinkHandler = (linkText) => {
    if(isLinkCopied) return;
    setIsLinkCopied(true);
    copyToClipboard(linkText);
  }

  useEffect(() => {
    if(!isEmailCopied) { return }
    setTimeout(() => {
      setIsEmailCopied(false);
    }, COPY_DELAY);
  }, [isEmailCopied]);

  useEffect(() => {
    if(!isLinkCopied) { return }
    setTimeout(() => {
      setIsLinkCopied(false);
    }, COPY_DELAY);
  }, [isLinkCopied]);

  // Generate New Link
  const [isGenerating, setIsGenerating] = useState(false);

  const generateNewLinkHandler = () => {
    if(isGenerating) return;
    
    setIsGenerating(true);

    try {
      fetch(API_TEST + `ConsentEvents/${props.eventId}/regenerate-download-link`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ id: props.eventId })
      }).then((response) => {
        if(response.status === 401) {
          fetch(API_TEST + 'Admin/refresh-access-token', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Cache': 'no-cache',
              'Authorization': `Bearer ${token}`
            },
            credentials: 'include',
            body: JSON.stringify({})
          }).then((resp) => {
            if(resp.status === 400) {
              authCtx.onLogOut();
              return;
            }
            return resp.json();
          }).then(data => {
            authCtx.setNewToken(data.accessToken);
            generateNewLinkHandler();
          });
          return;
        } else if(response.status === 400) {
          authCtx.onLogOut();
          return;
        }

        notificationCtx.onShowNotification("You have successfully generated new access link.");
        setIsGenerating(false);
      });
    } catch(err) {
      console.log(err);
      setIsGenerating(false);
    }
  }

  // Requests
  const rejectRequestHandler = () => {
    if(eventRequest === "requested" || eventRequest === "paid") {
      try {
        fetch(API_TEST + `ConsentEvents/${props.eventId}/reject`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ id: props.eventId })
        }).then((response) => {
          if(response.status === 401) {
            fetch(API_TEST + 'Admin/refresh-access-token', {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Cache': 'no-cache',
                'Authorization': `Bearer ${token}`
              },
              credentials: 'include',
              body: JSON.stringify({})
            }).then((resp) => {
              if(resp.status === 400) {
                authCtx.onLogOut();
                return;
              }
              return resp.json();
            }).then(data => {
              authCtx.setNewToken(data.accessToken);
              rejectRequestHandler();
            });
            return;
          } else if(response.status === 400) {
            authCtx.onLogOut();
            return;
          }

          props.onFetchConsentEvent();
          notificationCtx.onShowNotification("You have effectively rejected the retrieval request.");
          setRejectingEvent(false);
        });
      } catch(err) {
        console.log(err);
      }
    }
  }

  const openRejectDialog = () => {
    setRejectingEvent(true);
  }

  const closeRejectDialog = () => {
    setRejectingEvent(false);
  }

  const confirmRequestHandler = () => {
    if(eventRequest === "requested") {
      try {
        fetch(API_TEST + `ConsentEvents/${props.eventId}/approve`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ id: props.eventId })
        }).then((response) => {
          if(response.status === 401) {
            fetch(API_TEST + 'Admin/refresh-access-token', {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Cache': 'no-cache',
                'Authorization': `Bearer ${token}`
              },
              credentials: 'include',
              body: JSON.stringify({})
            }).then((resp) => {
              if(resp.status === 400) {
                authCtx.onLogOut();
                return;
              }
              return resp.json();
            }).then(data => {
              authCtx.setNewToken(data.accessToken);
              confirmRequestHandler();
            });
            return;
          } else if(response.status === 400) {
            authCtx.onLogOut();
            return;
          }

          props.onFetchConsentEvent();
          notificationCtx.onShowNotification("You have successfully approved the retrieval request for the specific Consent Event evidence.");
          setIsAuth(false);
        });
      } catch(err) {
        console.log(err);
      }
    }

    if(eventRequest === "paid") {
      try {
        fetch(API_TEST + `ConsentEvents/${props.eventId}/confirm`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ id: props.eventId })
        }).then((response) => {
          if(response.status === 401) {
            fetch(API_TEST + 'Admin/refresh-access-token', {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Cache': 'no-cache',
                'Authorization': `Bearer ${token}`
              },
              credentials: 'include',
              body: JSON.stringify({})
            }).then((resp) => {
              if(resp.status === 400) {
                authCtx.onLogOut();
                return;
              }
              return resp.json();
            }).then(data => {
              authCtx.setNewToken(data.accessToken);
              confirmRequestHandler();
            });
            return;
          } else if(response.status === 400) {
            authCtx.onLogOut();
            return;
          }

          props.onFetchConsentEvent();
          notificationCtx.onShowNotification("You have successfully confirmed the retrieval request for the specific Consent Event evidence.");
          setIsAuth(false);
        });
      } catch(err) {
        console.log(err);
      }
    }
  }

  const openAuthModalHandler = () => {
    if(adminData.auth) {
      if((eventRequest === "paid" && user.subscriptionType === 1 )|| user.subscriptionType === 2) {
        try {
          fetch(API_TEST + `Admin/${adminId}/2fa`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json-patch+json',
              'Authorization': `Bearer ${token}`
            }
          }).then((res) => {
            if(res.status === 401) {
              fetch(API_TEST + 'Admin/refresh-access-token', {
                method: 'POST',
                credentials: 'include',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
                  'Cookie': authCtx.refreshToken
                },
                body: JSON.stringify({})
              }).then((resp) => {
                if(resp.status === 400) {
                  authCtx.onLogOut();
                  return;
                }
                return resp.json();
              }).then(data => {
                authCtx.setNewToken(data.accessToken);
                openAuthModalHandler();
              });
              return;
            } else if(res.status === 400) {
              authCtx.onLogOut();
              return;
            }

            if(res.ok) {
              return { Succeeded: true }
            }
            setIsAuth(false);
            return res.json();
          }).then((data) => {
            return data;
          });
        } catch(err) {
          console.log(err.message);
        }
        setIsAuth(true);
        return;
      }
    }
    
    confirmRequestHandler();
  }

  const closeAuthModalHandler = () => {
    setIsAuth(false);
  }
  
  return (
    <>
      { isAuth && <AuthModal onSubmit={confirmRequestHandler} onCloseAuthModal={closeAuthModalHandler} adminId={adminId} authModalType="ce" /> }
      {
        rejectingEvent &&
        <Modal onCloseModal={closeRejectDialog} onConfirm={rejectRequestHandler}>
          <h3>REJECT REQUEST?</h3>
          <p>Are you certain about rejecting the retrieval request?</p>
          <p>The user will receive a notification that request has not been approved.</p>
        </Modal>
      }
      <div className="consent-event-single">
        <div className="consent-event-single-header">
          <div className="consent-event-single-id">
            <p><span>ID</span>{props.eventId}</p>
          </div>
          <div className="consent-event-single-status">
            <p>{ eventStatus }<span>Status</span></p>
          </div>
          {
            event.isArchived &&
            <div className="consent-event-single-archived">
              <p>
                <img src={iconArchived} alt="Archived icon" />
                <span>Archived</span>
              </p>
            </div>
          }
        </div>
        <div className="consent-event-single-body">
          <div className="consent-event-single-row">

            <div className="consent-event-single-col">
              <div className="consent-event-single-info">
                <div className="form-field input-field input-field-secondary">
                  <label>User's Phone Number</label>
                  <div className="input has-event">
                    {event.consentEvent.phoneNumber}
                    <div className="input-event">
                      <Link to={"/users/" + event.consentEvent.userId}>
                        <img src={iconEye} alt="Eye icon" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="form-field input-field input-field-secondary">
                  <label>User</label>
                  <div className="input">{event.consentEvent.name ? event.consentEvent.name : "-"}</div>
                </div>
                <div className="form-field input-field input-field-secondary">
                  <label>Created On</label>
                  <div className="input">
                    { getDateString(new Date(event.consentEvent.createdAt)) } <span>{ getTimeString(new Date(event.consentEvent.createdAt)) }</span><u>UTC</u>
                  </div>
                </div>
                <div className="form-field input-field input-field-secondary">
                  <label>Geolocation</label>
                  <div className="input has-event">
                    {event.consentEvent.latitude + "° N / " + event.consentEvent.longitude  +"° W"}
                    {
                      event.consentEvent.latitude && event.consentEvent.longitude &&
                      <div className="input-event">
                        <a href={`https://www.google.com/maps/search/?api=1&query=${event.consentEvent.latitude},${event.consentEvent.longitude}` } target="_blank" rel="noreferrer" ><img src={iconLocation} alt="Location icon" /></a>
                      </div>
                    }
                  </div>
                </div>
                <div className="form-field input-field input-field-secondary">
                  <label>Recipient's Phone Number</label>
                  <div className="input">{ event.consentEvent.sexualPartnerPhoneNumber ? event.consentEvent.sexualPartnerPhoneNumber : "-" }</div>
                </div>

                <div className="form-field input-field input-field-secondary note">
                  <label>Note</label>
                  <textarea placeholder="Enter a note" value={note} onChange={noteInputHandler}></textarea>
                  {
                    noteUpdated &&
                    <div className="note-buttons">
                      <span onClick={noteSaveHandler}><img src={iconAccept} alt="Accept icon" /></span>
                      <span onClick={noteCancelHandler}><img src={iconCancel} alt="Cancel icon" /></span>
                    </div>
                  }
                </div>

                <div className="questions role">
                  <div className="questions-row">
                    <div className="questions-col">
                      <div className="questions-role">
                        <p>Role<strong className={ eventType.toLowerCase() === "l" ? "type-limits" : ""}>{eventType}</strong><span>{ getConsentTypeName(eventType.toLowerCase()) }</span></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="consent-event-single-col">
              <div className="retrieval-title">
                <p>Retrieval</p>
                { !event.requestRetrieval && <span>** When a user requests retrieval, this information will become visible</span> }
              </div>
              <div className="retrieval">
                <div className="retrieval-row">
                  <p className="retrieval-label">REQUEST DETAILS</p>
                </div>
                <div className="retrieval-row retrieval-request">
                  <div className="form-field input-field input-field-secondary">
                    <label>Requested Status</label>
                  </div>
                  { eventRequest ?
                    <div className="retrieval-request-label">
                      <span className={eventRequest}>{eventRequest}</span>
                    </div> :
                    <div className="form-field input-field input-field-secondary">
                      <div className="input">-</div>
                    </div>
                  }
                </div>
                <div className="retrieval-row">
                  <div className="form-field input-field input-field-secondary">
                    <label>Requested On</label>
                  </div>
                  <div className="form-field input-field input-field-secondary">
                    {
                      event.requestRetrieval ?
                      <div className="input">{ getDateString(new Date(event.requestRetrieval.requestedAt)) }<span>{ getTimeString(new Date(event.requestRetrieval.requestedAt)) }</span><u>UTC</u></div> :
                      <div className="input">-</div>
                    }
                  </div>
                </div>
                <div className="retrieval-row retrieval-row-label">
                  <p className="retrieval-label">LEGAL REPRESENTATIVE DETAILS</p>
                </div>
                <div className="retrieval-row retrieval-row-info">
                  <div className="form-field input-field input-field-secondary">
                    <label>Organization</label>
                  </div>
                  <div className="form-field input-field input-field-secondary">
                    <div className="input">{event.requestRetrieval ? event.requestRetrieval.lawOfficeName : "-"}</div>
                  </div>
                </div>
                <div className="retrieval-row retrieval-row-info">
                  <div className="form-field input-field input-field-secondary">
                    <label>Law Enforcement <br/>Case</label>
                  </div>
                  <div className="form-field input-field input-field-secondary">
                    <div className="input">{event.requestRetrieval ? event.requestRetrieval.lawEnforcementCase : "-"}</div>
                  </div>
                </div>
                <div className="retrieval-row retrieval-row-info">
                  <div className="form-field input-field input-field-secondary">
                    <label>Contact Name</label>
                  </div>
                  <div className="form-field input-field input-field-secondary">
                    <div className="input">{event.requestRetrieval ? event.requestRetrieval.representativeName : "-"}</div>
                  </div>
                </div>
                <div className="retrieval-row retrieval-row-info">
                  <div className="form-field input-field input-field-secondary">
                    <label>Contact Phone</label>
                  </div>
                  <div className="form-field input-field input-field-secondary">
                    <div className="input">{event.requestRetrieval ? event.requestRetrieval.phoneNumber : "-"}</div>
                  </div>
                </div>
                <div className="retrieval-row retrieval-row-info with-icon">
                  <div className="form-field input-field input-field-secondary">
                    <label>Contact Email</label>
                  </div>
                  <div className="form-field input-field input-field-secondary">
                    {
                      event.requestRetrieval && event.requestRetrieval.email ?
                      <div className="input">
                        {event.requestRetrieval.email}
                        <div className="input-event">
                          <div className={isEmailCopied ? "event-copy disabled" : "event-copy"} onClick={() => { copyEmailHandler(event.requestRetrieval.email); }}>
                            <img src={iconCopy} alt="Date icon" />
                          </div>
                        </div>
                      </div> :
                      <div className="input">-</div>
                    }
                  </div>
                </div>
                <div className="retrieval-functions">
                  <div className="form-field input-field input-field-secondary input-field-large">
                    <label>Access link</label>
                    {
                      event.requestRetrieval && event.requestRetrieval.accessUrl ?
                      <div className="input has-event">
                        {event.requestRetrieval.accessUrl}
                        <div className="input-event">
                          <div className={isLinkCopied ? "tooltip disabled" : "tooltip"} onClick={() => { copyLinkHandler(event.requestRetrieval.accessUrl); }}>
                            <div className={isLinkCopied ? "event-copy disabled" : "event-copy"}>
                              <img src={iconCopy} alt="Date icon" />
                            </div>
                            <div className="tooltip-content">Copy</div>
                          </div>
                        </div>
                        <div className="input-event">
                          <div className={isGenerating ? "tooltip disabled" : "tooltip"} onClick={generateNewLinkHandler}>
                            <div className={isGenerating ? "event-copy disabled" : "event-copy"}>
                              <img src={iconRotate} alt="Rotate icon" />
                            </div>
                            <div className="tooltip-content">Reactivate link</div>
                          </div>
                        </div>
                      </div> :
                      <div className="input">-</div>
                    }
                  </div>
                  <div className="retrieval-buttons">
                    <button className="btn btn-outline btn-small" disabled={eventRequest !== "requested" && eventRequest !== "paid"} onClick={openRejectDialog}>REJECT REQUEST</button>
                    <button className="btn btn-primary btn-small" disabled={eventRequest !== "requested" && eventRequest !== "paid"} onClick={openAuthModalHandler}><span>CONFIRM REQUEST</span></button>
                  </div>
                </div>
              </div>
              <ConsentEventHistory eventStatus={event.requestRetrieval ? event.requestRetrieval.history : null} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ConsentEventSingleTable;