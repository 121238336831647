import React from 'react';

import iconArrowRight from '../../assets/images/icon-arrow-right.svg';

const Pagination = (props) => {
  const pageCount = props.pagesLength;

  let pages = [];

  for (let i = 1; i <= pageCount; i++) {
    if (pageCount > 3) {
      if (i === props.page + 3) break;
      if (i < props.page && props.page < pageCount - 2) {
        continue;
      } else if (props.page >= pageCount - 2) {
        pages = [pageCount - 2, pageCount - 1, pageCount];
        break;
      }
    }
    pages.push(i);
  }

  const changePageHandler = (newPage) => {
    if(props.page === newPage) return;
    props.onPageChange(newPage);
  }

  const nextPageHandler = () => {
    const nextPage = props.page + 1;
    if(nextPage > pageCount) return;
    props.onPageChange(nextPage);
  }

  const prevPageHandler = () => {
    const prevPage = props.page - 1;
    if(prevPage < 1) return;
    props.onPageChange(prevPage);
  }

  return (
    <div className="pagination">
      <button className={props.page === 1 ? "pagination-btn pagination-prev pagination-btn-disabled" : "pagination-btn pagination-prev"} onClick={prevPageHandler}><img src={iconArrowRight} alt="Right arrow icon" /></button>
      <ul>
        {
          pages.map((pageNumber) => {
            return <li className={pageNumber === props.page ? "pagination-current" : ""} onClick={() => changePageHandler(pageNumber)} key={"page-" + pageNumber}>{pageNumber}</li>
          })
        }
      </ul>
      <button className={props.page === pageCount ? "pagination-btn pagination-next pagination-btn-disabled" : "pagination-btn pagination-next"} onClick={nextPageHandler}><img src={iconArrowRight} alt="Right arrow icon" /></button>
    </div>
  );
}

export default Pagination;