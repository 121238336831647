import React, { useContext, useEffect, useState } from "react";

import iconPhone from "../../assets/images/icon-phone.svg";
import AuthContext from "../../store/auth-context";
import { API_TEST } from "../../utils/helpers";

const TwoFactorAuth = (props) => {
  const authCtx = useContext(AuthContext);
  const [authCode, setAuthCode] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [authCodeExpired, setAuthCodeExpired] = useState(false);
  const [isResending, setIsResending] = useState(false);

  const authCodeChangeHandler = (e) => {
    setAuthCode(e.target.value);
  };

  const submitFormHandler = (e) => {
    e.preventDefault();

    setIsSubmitted(true);

    if (!authCode || isSubmitted) return;

    try {
      fetch(API_TEST + `Admin/${props.response.data.id}/2fa/confirm?code=${authCode}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json-patch+json'
        }
      }).then((res) => {
        if(res.ok) {
          return { Succeeded: true }
        }
        return res.json();
      }).then((data) => {
        if(!data.Succeeded) {
          if(data.Message.indexOf('expired')) {
            setAuthCodeExpired(true);
            return;
          }
          
          props.showErrorHandler(data.Message);
          return;
        }

        authCtx.setAuthToken(
          props.response.token,
          props.response.refreshToken,
          props.response.data.id,
          props.response.data,
          props.adminRememberMe
        );
      });
    } catch(err) {
      console.log(err.message);
    }

    setIsSubmitted(false);
  };

  const resendAuthCode = () => {
    setIsResending(true);
    setAuthCodeExpired(false);
    props.showErrorHandler("");

    try {
      fetch(API_TEST + `Admin/resend-verification-code?adminId=${props.response.data.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json-patch+json'
        }
      }).then((res) => {
        if(res.ok) {
          return { Succeeded: true }
        }
        return res.json();
      }).then((data) => {
        if(!data.Succeeded) {
          props.showErrorHandler(data.Message);
          return;
        }
      });
    } catch(err) {
      console.log(err.message);
    }

    setIsResending(false);
  }

  useEffect(() => {
    try {
      fetch(API_TEST + `Admin/${props.response.data.id}/2fa`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json-patch+json'
        }
      }).then((res) => {
        if(res.ok) {
          return { Succeeded: true }
        }
        return res.json();
      }).then((data) => {
        return data;
      });
    } catch(err) {
      console.log(err.message);
    }
  }, [props.response.data.id]);

  return (
    <>
      <h2>Two-Factor Authentication</h2>

      <div className="auth">
        <div className="auth-icon">
          <img src={iconPhone} alt="Phone icon" />
        </div>
        <div className="auth-content">
          <p>Enter the 6-digit code generated by authentication app.</p>
        </div>
      </div>

      <form className="auth-form" onSubmit={submitFormHandler}>
        <div className={ authCodeExpired ? "form-field input-field form-field-error" : "form-field input-field"}>
          <label htmlFor="auth-code">Authentication Code</label>
          <input
            type="text"
            name="auth-code"
            id="auth-code"
            onChange={authCodeChangeHandler}
            value={authCode}
            placeholder="Enter code"
          />
          { authCodeExpired && <p className="error-text">The verification code expired. Please try again.</p> }
        </div>
        <div className="form-submit">
          <button className="btn btn-primary btn-large" disabled={isSubmitted}>
            <span>Verify</span>
          </button>
          <span className="btn-link" onClick={resendAuthCode} style={{pointerEvents: isResending ? "none" : "all"}}>Resend Code</span>
        </div>
      </form>
    </>
  );
};

export default TwoFactorAuth;
