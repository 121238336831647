import React from 'react';
import ReactDOM from 'react-dom';

import logoSad from '../../assets/images/logo-sad.svg';

const ModalBackdrop = (props) => {
  return (
    <div className="auth-modal-overlay" onClick={props.onCloseModal}></div>
  )
}

const ModalOverlay = (props) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <img src={logoSad} alt="Peach Protector logo" />
        { props.children }
        <div className="modal-buttons">
          <button className="btn btn-outline btn-small" onClick={props.onCloseModal}>CANCEL</button>
          <button className="btn btn-primary btn-small" onClick={props.onConfirm}><span>CONFIRM</span></button>
        </div>
      </div>
    </div>
  );
};

const Modal = (props) => {
  return (
    <>
      {ReactDOM.createPortal(
        <ModalBackdrop onCloseModal={props.onCloseModal} />,
        document.getElementById('overlay-root')
      )}
      {ReactDOM.createPortal(
        <ModalOverlay onCloseModal={props.onCloseModal} onConfirm={props.onConfirm}>{ props.children }</ModalOverlay>,
        document.getElementById('overlay-root')
      )}
    </>
  )
}

export default Modal;