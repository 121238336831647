import downloadBg from '../../assets/images/download-bg-3.png';
import DownloadProgressImg from '../../assets/images/download-progress-image.png';

const DownloadExpired = () => {
  return (
    <div className="download">
      <div className="download-container">
        <div className="download-content-wrap">
          <div className="download-content">
            <div className="download-title">
              <h2 className="red-text">Download Link Expired</h2>
            </div>
            <p>We're sorry to inform you that the download link for the requested CE recording has expired. To maintain the security of your content, our download links come with an expiration time.</p>
            <p>If you'd like to access the material, please feel free to submit a new request, or get in touch with us if you have any additional questions.</p>
            <img src={DownloadProgressImg} alt="Download in progress" style={{opacity: "0.25"}} />
            <p className="text-center"><a href="mailto:support@peachprotector.com" target="_top">Contact Support</a></p>
          </div>
        </div>
        <div className="download-sidebar"><img src={downloadBg} alt="Download background" /></div>
      </div>
    </div>
  );
}

export default DownloadExpired;