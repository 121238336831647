import { useContext, useEffect, useState } from 'react';

import downloadBg from '../../assets/images/download-bg.png';
import { API_TEST } from '../../utils/helpers';
import Loading from '../UI/Loading';
import { useParams } from 'react-router-dom';
import AuthContext from '../../store/auth-context';

const CEERetrievalPayment = () => {
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  const { event_id } = useParams();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    try {
      const paymentData = {
        id: event_id
      }
      fetch(API_TEST + `ConsentEvents/${event_id}/email-payment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json-patch+json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(paymentData)
      }).then((res) => {
        return res.json();
      }).then((data) => {
        setIsLoading(false);
        if(data.Succeeded === false) {
          setIsLoading(false);
          setError(data.Message);
          return;
        }
        if(data.checkoutSessionUrl) {
          window.open(data.checkoutSessionUrl, "_self");
          setError("");
        }
      });
    } catch(err) {
      console.log(err);
      setError(err.message);
      setIsLoading(false);
    }
  }, [event_id, token]);
  
  return (
    <div className="download revoke-page">
      <div className="download-container">
        <div className="download-content-wrap">
          <div className="download-content">
            <div className="download-title">
              <h2>Payment in Progress</h2>
            </div>
            <p>Please keep your computer connected to the internet during the payment process.</p>
            { isLoading && <Loading /> }
            <p className="text-center"><a href="mailto:support@peachprotector.com" target="_top">Contact Support</a></p>
            { error && <p className="error-text text-center">{error}</p>}
          </div>
        </div>
        <div className="download-sidebar"><img src={downloadBg} alt="Download background" /></div>
      </div>
    </div>
  );
}

export default CEERetrievalPayment;