import { useState } from 'react';
import iconArrow from '../../assets/images/icon-arrow-down.svg';
import { getDateString, getTimeString } from '../../utils/helpers';

const ConsentEventHistory = (props) => {
  const [isActive, setIsActive] = useState(false);

  const openHistoryHandler = () => {
    if(!props.eventStatus) { return; }
    setIsActive(prevState => !prevState);
  }

  return (
    <div className={isActive ? "questions active" : "questions"} onClick={openHistoryHandler} style={{cursor: props.eventStatus ? 'pointer' : 'default'}}>
      <div className="questions-row">
        <div className="questions-col">
          <div className="questions-content">
            <p>RETRIEVAL Request Status Log {!props.eventStatus && <span>No status to display</span>} <img src={iconArrow} alt="Down arrow icon" /></p>
            <div className="questions-list">
              <ul className="history">
                {
                  props.eventStatus && props.eventStatus.map((event, index) => {
                    // Request Retrieval Status
                    let eventRequest = null;
                    if(event.status === 1) { eventRequest = 'requested'; }
                    if(event.status === 2) { eventRequest = 'rejected'; }
                    if(event.status === 3) { eventRequest = 'pending'; }
                    if(event.status === 4) { eventRequest = 'confirmed'; }
                    if(event.status === 5) { eventRequest = 'paid'; }
                    if(event.status === 6) { eventRequest = 'retrieved'; }
                    
                    return (
                      <li key={`request-status-${index + 1}`}>
                        <div className="history-row history-status">
                          <p>Request Status</p>
                          <p><span className={eventRequest}>{eventRequest}</span></p>
                        </div>
                        <div className="history-row history-date">
                          <p>Requested On</p>
                          <p>{ getDateString(new Date(event.timestamp)) }<span>{ getTimeString(new Date(event.timestamp)) }</span> UTC</p>
                        </div>
                      </li>
                    );
                  })
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConsentEventHistory;