import React from 'react';

import errorImg from '../../assets/images/error-404.png';
import { Link } from 'react-router-dom';

const ErrorPage = () => {
  return (
    <div className="error-page">
      <div className="error-page-content">
        <img src={errorImg} alt="Error 404" />
        <p>The page you requested is not found!</p>
        <Link to="/" className="btn btn-primary"><span>HOMEPAGE</span></Link>
      </div>
    </div>
  )
}

export default ErrorPage;