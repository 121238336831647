import React from 'react';

import iconSort from '../../assets/images/icon-sort.svg';
import UsersItem from './UsersItem';
import NoResults from '../UI/NoResults';

const UsersTable = (props) => {
  const usersTableHeader = (
    <thead>
      <tr>
        <th><span onClick={props.onUserNameSort}>User<img src={iconSort} alt="Sort icon" /></span></th>
        <th>Phone Number</th>
        <th>Date of Birth</th>
        <th>Subscription</th>
        <th>Expires On</th>
        <th>Created CE</th>
      </tr>
    </thead>
  );

  return (
    <div className="consent-events users">
      <table>
        { usersTableHeader }
        <tbody>
          { props.users && props?.users?.length > 0 && props.users.map(user => <UsersItem user={user} key={user.id} />) }
          { !props.users?.length && <NoResults />}
        </tbody>
      </table>
    </div>
  )
}

export default UsersTable;