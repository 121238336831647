import React from 'react';

import iconSort from '../../assets/images/icon-sort.svg';
import ConsentEventsItem from './ConsentEventsItem';
import NoResults from '../UI/NoResults';

const ConsentEventsTable = (props) => {
  const eventsTableHeader = (
    <thead>
      <tr>
        <th>ID Number</th>
        <th><span onClick={props.onEventsUserSort}>User<img src={iconSort} alt="Sort icon" /></span></th>
        <th>User's Phone</th>
        <th style={{width: '13%'}}><span onClick={props.onEventsDateSort}>Created On<img src={iconSort} alt="Sort icon" /></span></th>
        <th>Geolocation</th>
        <th>Recipient's Phone</th>
        <th className="consent-events-type">Type</th>
        <th>Status</th>
        <th>Retrieval Request</th>
      </tr>
    </thead>
  );

  return (
    <div className="consent-events">
      <table>
        { eventsTableHeader }
        <tbody>
          { props.events && props?.events?.length > 0 && props.events.map(consentEvent => <ConsentEventsItem consentEvent={consentEvent} key={consentEvent.id} />) }
          { !props.events?.length && <NoResults />}
        </tbody>
      </table>
    </div>
  )
}

export default ConsentEventsTable;