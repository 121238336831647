import { useContext, useEffect, useState } from 'react';
import downloadBg from '../../assets/images/download-bg-2.png';
import { useSearchParams } from 'react-router-dom';
import { API_TEST } from '../../utils/helpers';
import ErrorPage from '../UI/ErrorPage';
import Loading from "../UI/Loading";
import AuthContext from '../../store/auth-context';

const DownloadRecording = (props) => {
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  const [searchParams] = useSearchParams();
  const userId = searchParams.get("uid");
  const userPhone = searchParams.get("userPhone");
  const eventId = searchParams.get("cid");
  const requestId = searchParams.get("rrid");

  const [error, setError] = useState("");
  const [isEnabled, setIsEnabled] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);

  const passphraseInputChange = (val, fn) => {
    fn(val);
  }
 
  const [passphrase1, setPassphrase1] = useState("");
  const passphraseInputHandler = (e) => { passphraseInputChange(e.target.value.trim(), setPassphrase1); }

  const [passphrase2, setPassphrase2] = useState("");
  const passphraseInputHandler2 = (e) => { passphraseInputChange(e.target.value.trim(), setPassphrase2); }

  const [passphrase3, setPassphrase3] = useState("");
  const passphraseInputHandler3 = (e) => { passphraseInputChange(e.target.value.trim(), setPassphrase3); }

  const [passphrase4, setPassphrase4] = useState("");
  const passphraseInputHandler4 = (e) => { passphraseInputChange(e.target.value.trim(), setPassphrase4); }

  const [passphrase5, setPassphrase5] = useState("");
  const passphraseInputHandler5 = (e) => { passphraseInputChange(e.target.value.trim(), setPassphrase5); }

  const [passphrase6, setPassphrase6] = useState("");
  const passphraseInputHandler6 = (e) => { passphraseInputChange(e.target.value.trim(), setPassphrase6); }

  const [passphrase7, setPassphrase7] = useState("");
  const passphraseInputHandler7 = (e) => { passphraseInputChange(e.target.value.trim(), setPassphrase7); }

  const [passphrase8, setPassphrase8] = useState("");
  const passphraseInputHandler8 = (e) => { passphraseInputChange(e.target.value.trim(), setPassphrase8); }

  const [passphrase9, setPassphrase9] = useState("");
  const passphraseInputHandler9 = (e) => { passphraseInputChange(e.target.value.trim(), setPassphrase9); }

  const [passphrase10, setPassphrase10] = useState("");
  const passphraseInputHandler10 = (e) => { passphraseInputChange(e.target.value.trim(), setPassphrase10); }

  const [passphrase11, setPassphrase11] = useState("");
  const passphraseInputHandler11 = (e) => { passphraseInputChange(e.target.value.trim(), setPassphrase11); }

  const [passphrase12, setPassphrase12] = useState("");
  const passphraseInputHandler12 = (e) => { passphraseInputChange(e.target.value.trim(), setPassphrase12); }

  const checkPassphrases = () => passphrase1 !== "" && passphrase2 !== "" && passphrase3 !== "" && passphrase4 !== "" && passphrase5 !== "" && passphrase6 !== "" && passphrase7 !== "" && passphrase8 !== "" && passphrase9 !== "" && passphrase10  !== ""  && passphrase11  !== ""  && passphrase12  !== "" ;

  useEffect(() => {
    if(passphrase1 !== "" && passphrase2 !== "" && passphrase3 !== "" && passphrase4 !== "" && passphrase5 !== "" && passphrase6 !== "" && passphrase7 !== "" && passphrase8 !== "" && passphrase9 !== "" && passphrase10  !== ""  && passphrase11  !== ""  && passphrase12  !== "") { setIsEnabled(false); }
    else { setIsEnabled(true); }
  }, [passphrase1, passphrase2, passphrase3, passphrase4, passphrase5, passphrase6, passphrase7, passphrase8, passphrase9, passphrase10, passphrase11, passphrase12]);

  const passphraseSubmitHandler = (e) => {
    e.preventDefault();
    const passphraseList = [passphrase1, passphrase2, passphrase3, passphrase4, passphrase5, passphrase6, passphrase7, passphrase8, passphrase9, passphrase10, passphrase11, passphrase12].join(" ");

    if(isDownloading) {
      return;
    }

    setError("");

    if(!checkPassphrases()) {
      setError("Fill all passphrases.");
      props.onDownload("");
      return;
    }

    try {
      setIsDownloading(true);
      const downloadData = {
        userId: userId,
        requestRetrievalId: requestId,
        passPhrase: passphraseList
      }
      fetch(API_TEST + `ConsentEvents/${eventId}/admin/download`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json-patch+json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(downloadData)
      }).then((res) => {
        if(!res.ok) {
          return res.json();
        }
        return res.blob();
      }).then((data) => {
        if(!data.Data && data.Succeeded === false) {
          setIsDownloading(false);
          if(data.Message.toLowerCase().indexOf('expired') >= 0) {
            props.onDownload("expired");
            return;
          }
          setError(data.Message);
          return;
        }

        const href = URL.createObjectURL(data);

        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `${eventId}.${data.type === "application/zip" ? "zip" : "mp4"}`);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        setIsDownloading(false);
        props.onDownload("in-progress");
      });
    } catch(err) {
      console.log(err);
    }
  }

  if(!userId || !eventId || !requestId) {
    return <ErrorPage />
  }

  return (
    <div className="download download-cee">
      <div className="download-container">
        <div className="download-content-wrap">
          <div className="download-title">
            <h2 style={{lineHeight: '40px'}}>Authenticate to Download <br />Consent Event Evidence</h2>
          </div>
          <div className="download-content">
            {
              <>
                <p>PeachProtector staff <span>does not have access</span> to our client's decryption passphrases and we <span>cannot generate new decryption passphrases</span> to decrypt existing Consent Event Evidence (CEE). Only the account owner (e.g., individual using the PeachProtector app) will have access to those decryption passphrases that will decrypt corresponding Consent Event (CE) file(s).</p>
                <p>During that account setup process, the account owner was required to email the decryption passphrases to their email address. <span>These passphrases are necessary to download your client's CEE.</span> We feel it is important to note that it is possible for some account owners to have multiple passphrases, since new passphrases will be generated during the account setup process when the account owner (1) installs PeachProtector on a new mobile device or (2) manually logs out of the PeachProtector app (within the settings function of the PeachProtector app) and logs back into the PeachProtector app. The email sent to the account owner's email address will indicate the date and time from when the automatically-generated passphrases are effective. The passphrases that correspond to the pertinent date & time must be used to download and decrypt the CE file.</p>
                <p>To download the CE file, you are required to enter the account owner's decryption passphrases that corresponds to the passphrases that <span>were active at the date & time the CE was collected.</span></p>
                <p>Once you've been able to download the decrypted video file, you will have a Zip file that is labelled with the CE file number. You will need to download the <a href="https://www.gomlab.com/en/gomplayer-media-player" target="_blank" rel="noreferrer"><strong>GOM Player</strong></a>, which is a free video player. Steps to view the CEE:</p>
                <ol>
                  <li>
                    You must first extract the files from the Zip file:
                    <ol>
                      <li>On a Microsoft Windows computer, right-click the Zipped file and select “Extract All” to extract the files to your desired location.</li>
                      <li>On an Apple (Mac) computer, select the Zipped file and double-click to extract the files to your desired location.</li>
                    </ol>
                  </li>
                  <li>Open GOM Player application on your computer.</li>
                  <li>From the GOM Player's menu bar, select “Open”.</li>
                  <li>Navigate to the folder where you extracted the Zipped file, select the file and click “Open“</li>
                  <li>The video will show the rear-facing camera (e.g., selfie view) that was used to record the CE on the account owner's mobile device.</li>
                  <li>The video will show a textual overlay of the CE file number (e.g., CE xRc3SE9V) in white text.</li>
                  <li>Below the CE file number, the video will also show a textual overlay of the CE question being asked.</li>
                </ol>
                <div className="download-info">
                  <p><span>Consent Event ID Number</span>{eventId}</p>
                  <p><span>Phone Number</span>{userPhone && `+${userPhone}`}</p>
                </div>
                <form className="passphrase" onSubmit={passphraseSubmitHandler}>
                  <p>Secret Passphrase</p>
                  <div className="passphrase-row">
                    <div className="passphrase-col">
                      <div className="passphrase-input">
                        <label>1.</label>
                        <input name="passphrase1" id="passphrase1" className={passphrase1 !== "" ? "input-filled" : "" } value={passphrase1} onChange={passphraseInputHandler} />
                      </div>
                      <div className="passphrase-input">
                        <label>2.</label>
                        <input name="passphrase2" id="passphrase2" className={passphrase2 !== "" ? "input-filled" : "" } value={passphrase2} onChange={passphraseInputHandler2} />
                      </div>
                      <div className="passphrase-input">
                        <label>3.</label>
                        <input name="passphrase3" id="passphrase3" className={passphrase3 !== "" ? "input-filled" : "" } value={passphrase3} onChange={passphraseInputHandler3} />
                      </div>
                      <div className="passphrase-input">
                        <label>4.</label>
                        <input name="passphrase4" id="passphrase4" className={passphrase4 !== "" ? "input-filled" : "" } value={passphrase4} onChange={passphraseInputHandler4} />
                      </div>
                    </div>
                    <div className="passphrase-col">
                      <div className="passphrase-input">
                        <label>5.</label>
                        <input name="passphrase5" id="passphrase5" className={passphrase5 !== "" ? "input-filled" : "" } value={passphrase5} onChange={passphraseInputHandler5} />
                      </div>
                      <div className="passphrase-input">
                        <label>6.</label>
                        <input name="passphrase6" id="passphrase6" className={passphrase6 !== "" ? "input-filled" : "" } value={passphrase6} onChange={passphraseInputHandler6} />
                      </div>
                      <div className="passphrase-input">
                        <label>7.</label>
                        <input name="passphrase7" id="passphrase7" className={passphrase7 !== "" ? "input-filled" : "" } value={passphrase7} onChange={passphraseInputHandler7} />
                      </div>
                      <div className="passphrase-input">
                        <label>8.</label>
                        <input name="passphrase8" id="passphrase8" className={passphrase8 !== "" ? "input-filled" : "" } value={passphrase8} onChange={passphraseInputHandler8} />
                      </div>
                    </div>
                    <div className="passphrase-col">
                      <div className="passphrase-input">
                        <label>9.</label>
                        <input name="passphrase9" id="passphrase9" className={passphrase9 !== "" ? "input-filled" : "" } value={passphrase9} onChange={passphraseInputHandler9} />
                      </div>
                      <div className="passphrase-input">
                        <label>10.</label>
                        <input name="passphrase10" id="passphrase10" className={passphrase10 !== "" ? "input-filled" : "" } value={passphrase10} onChange={passphraseInputHandler10} />
                      </div>
                      <div className="passphrase-input">
                        <label>11.</label>
                        <input name="passphrase11" id="passphrase11" className={passphrase11 !== "" ? "input-filled" : "" } value={passphrase11} onChange={passphraseInputHandler11} />
                      </div>
                      <div className="passphrase-input">
                        <label>12.</label>
                        <input name="passphrase12" id="passphrase12" className={passphrase12 !== "" ? "input-filled" : "" } value={passphrase12} onChange={passphraseInputHandler12} />
                      </div>
                    </div>
                  </div>
                  <div className="passphrase-submit">
                    { !isDownloading && <button className="btn btn-primary" disabled={isEnabled}><span>DOWNLOAD</span></button> }
                    { error && <p className="error-text">{error}</p>}
                    { isDownloading && <Loading /> }
                  </div>
                  <div className="passphrase-link">
                    <a href="mailto:support@peachprotector.com" target="_top">Contact Peach Protector Support</a>
                  </div>
                </form>
              </>
            }
          </div>
        </div>
        <div className="download-sidebar"><img src={downloadBg} alt="Download background" /></div>
      </div>
    </div>
  );
}

export default DownloadRecording;