import React, { useState } from 'react';

import iconSearch from '../../assets/images/icon-search.svg';

const Search = (props) => {
  const [search, setSearch] = useState("");
  const [isActive, setIsActive] = useState(false);

  const toggleSearchHandler = () => {
    setIsActive(prevState => !prevState);
  }

  const changeSearchHandler = (e) => {
    setSearch(e.target.value);
    props.onSearch(e.target.value);
  }

  return (
    <div className={isActive ? "search search-active" : "search"}>
      <input type="text" name="search" id="search" placeholder={props.placeholder} onChange={changeSearchHandler} value={search} />
      <button type="button" onClick={toggleSearchHandler}><img src={iconSearch} alt="Right arrow icon" /></button>
    </div>
  );
}

export default Search;