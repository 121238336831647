import downloadBg from '../../assets/images/download-bg.png';

const RevokeSuccess = (props) => {
  const fullDate = props.date;
  const date = fullDate.slice(0, fullDate.indexOf(" "));
  const time = fullDate.slice(fullDate.indexOf(" ") + 1, fullDate.lastIndexOf(":"));
  
  return (
    <div className="download revoke-page">
      <div className="download-container">
        <div className="download-content-wrap">
          <div className="download-content">
            <div className="download-title">
              <h2>Consent Event Revoked</h2>
            </div>
            <div className="revoke-content">
              <p>Consent Event has been successfully revoked!</p>
              <div className="revoke-content-date">
                <p>Date and Time:</p>
                <p>{date}<span>{time}</span></p>
              </div>
              <p>If this was not your intention, please feel free to reach out to us at <a href="mailto:support@peachprotector.com" target="_top">support@peachprotector.com</a>.</p>
            </div>
          </div>
        </div>
        <div className="download-sidebar"><img src={downloadBg} alt="Download background" /></div>
      </div>
    </div>
  );
}

export default RevokeSuccess;