import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useParams } from "react-router-dom";
import ContainerTitle from '../UI/ContainerTitle';
import Loading from '../UI/Loading';
import ErrorMsg from '../UI/ErrorMsg';
import ConsentEventSingleTable from './ConsentEventSingleTable';
import { API_TEST } from '../../utils/helpers';
import AuthContext from '../../store/auth-context';
import Cookies from 'js-cookie';

const ConsentEventSingle = () => {
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  const { event_id } = useParams();

  const [event, setEvent] = useState(null);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchConsentEvent = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(API_TEST + `ConsentEvents/${event_id}/admin`, { headers: {Authorization: `Bearer ${token}`} });

      if(response.status === 401) {
        Cookies.set("refreshToken", authCtx.refreshToken, { expires: 90 });
        fetch(API_TEST + 'Admin/refresh-access-token', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Cache': 'no-cache',
            'Authorization': `Bearer ${token}`
          },
          credentials: 'include',
          body: JSON.stringify({})
        }).then((resp) => {
          if(resp.status === 400) {
            authCtx.onLogOut();
            return;
          }
          return resp.json();
        }).then(data => {
          authCtx.setNewToken(data.accessToken);
        });
        return;
      } else if(response.status === 400) {
        authCtx.onLogOut();
        return;
      }

      if (!response.ok) {
        throw new Error('Something went wrong!');
      }
 
      const data = await response.json();

      if (!data) {
        throw new Error('There is no events!');
      }

      if(data) {
        try {
          const userResponse = await fetch(API_TEST + `Users/${data.consentEvent.userId}/admin`, { headers: {Authorization: `Bearer ${token}`} });
          if (!userResponse.ok) {
            throw new Error("Something went wrong!");
          }

          const userData = await userResponse.json();

          if (!userData) {
            throw new Error("There is no user!");
          }

          setUser(userData);
        } catch (error) {
          console.log(error.message);
        }
      }
      
      setEvent(data);
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, [event_id, authCtx, token]);

  const updateNote = async (noteVal) => {
    const eventVal = event;
    eventVal.note = noteVal;
    setEvent(eventVal);

    fetch(API_TEST + `ConsentEvents/${event_id}/admin/notes`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ note: noteVal})
    }).then(() => { console.log('Note updated!')});
  }

  useEffect(() => {
    fetchConsentEvent();
  }, [fetchConsentEvent]);

  let content = <Loading />;

  if(event) {
    content = <ConsentEventSingleTable eventId={event_id} event={event} user={user} onUpdateNote={updateNote} onFetchConsentEvent={fetchConsentEvent} />;
  }

  if (error) {
    content =  <ErrorMsg error={error} />;
  }

  if (isLoading) {
    content = <Loading />;
  }

  return (
    <div className="container container-table">
      <div className="container-header">
        <ContainerTitle title={"Consent Events"} parentPageLink={"/"} childPageTitle={"Single CE"} />
      </div>
      <div className="container-content">{content}</div>
    </div>
  );
}

export default ConsentEventSingle;