import React from 'react';

import noResultsImage from '../../assets/images/no-results-image.png';

const NoResults = () => {
  return (
    <tr className="no-results">
      <td>
        <img src={noResultsImage} alt="No results" />
        <h3>NO RESULTS FOUND</h3>
        <p>We couldn't find what you searched for. Try searching again.</p>
      </td>
    </tr>
  );
}

export default NoResults;