import React, { useContext } from 'react';

import iconCloseSuccess from '../../assets/images/icon-close-notification-success.svg';
import iconCloseError from '../../assets/images/icon-close-notification-error.svg';
import NotificationContext from '../../store/notification-context';

const Notification = () => {
  const notificationCtx = useContext(NotificationContext);

  return (
    <div className={"notificaton " + notificationCtx.type}>
      <div className="notificaton-content">
        <p>{notificationCtx.message}</p>
      </div>
      <span className="notificaton-close" onClick={notificationCtx.onHideNotification}>
        { notificationCtx.type === "success" ? <img src={iconCloseSuccess} alt="Close icon" /> : <img src={iconCloseError} alt="Close icon" /> }
      </span>
    </div>
  );
}

export default Notification;