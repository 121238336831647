import React, { useContext, useState } from "react";

import iconEye from "../../assets/images/icon-eye.svg";
import iconEyeVisible from "../../assets/images/icon-eye-visible.svg";
import iconCheckmark from "../../assets/images/icon-checkmark.svg";
import useForm from "../../hooks/use-form";
import AuthContext from "../../store/auth-context";

const SignInForm = (props) => {
  const authCtx = useContext(AuthContext);
  const {
    value: email,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
  } = useForm((value) => /(.+)@(.+){2,}\.(.+){2,}/.test(value));

  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLocked, setIsLocked] = useState(false);

  const passwordChangeHandler = (e) => {
    setPassword(e.target.value);
  };

  const rememberMeChangeHandler = (e) => {
    setRememberMe(e.target.checked);
    props.setAdminRememberMeHandler(e.target.checked);
  };

  const showPasswordHandler = () => {
    setShowPassword((prevVal) => !prevVal);
  };

  const submitFormHandler = async (e) => {
    e.preventDefault();

    if (!emailIsValid || !password) {
      props.showErrorHandler("Fill in all fields.");
    }

    if (!emailIsValid || isSubmitted) return;

    setIsSubmitted(true);

    const response = await authCtx.onLogIn(email, password, rememberMe);

    setIsSubmitted(false);

    if (!response.ok) {
      props.showErrorHandler(response.error);
      if(response.error.includes("locked")) { setIsLocked(true); }
      return;
    }

    const { data } = response;

    if (data.auth) {
      props.showAuthHandler(response);
    } else {
      authCtx.setAuthToken(response.token, response.refreshToken, data.id, data, rememberMe);
    }

    props.showErrorHandler("");
  };

  return (
    <div style={{opacity: isLocked ? 0.5 : 1, pointerEvents: isLocked ? 'none' : 'all'}}>
      <h2>Sign In</h2>
      <form className="sign-in-form" onSubmit={submitFormHandler}>
        <div className="form-field input-field">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            onChange={emailChangeHandler}
            onBlur={emailBlurHandler}
            value={email}
            placeholder="Enter your email address"
          />
          {emailHasError && <p className="error-text">Email is not valid</p>}
        </div>
        <div className="form-field input-field">
          <label htmlFor="password">Password</label>
          <div className="password-field">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              id="password"
              onChange={passwordChangeHandler}
              value={password}
              placeholder="Enter your password"
            />
            {password && (
              <span
                className={
                  showPassword ? "show-password active" : "show-password"
                }
                onClick={showPasswordHandler}
              >
                <img
                  src={showPassword ? iconEyeVisible : iconEye}
                  alt="Eye icon"
                />
              </span>
            )}
          </div>
        </div>
        <div className="form-field checkbox-field" style={{display: "none"}}>
          <div className="checkbox">
            <input
              type="checkbox"
              name="rememberMe"
              id="rememberMe"
              checked={rememberMe}
              onChange={rememberMeChangeHandler}
            />
            <label htmlFor="rememberMe" className="checkbox-checkmark">
              <img src={iconCheckmark} alt="Checkmark icon" />
            </label>
          </div>
          <label htmlFor="rememberMe">Keep me signed in</label>
        </div>
        <div className="form-submit">
          <button className="btn btn-primary btn-large" disabled={isSubmitted}>
            <span>Sign in</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default SignInForm;
