import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';

import ContainerTitle from '../UI/ContainerTitle';
import ConsentEventsTable from '../ConsentEvents/ConsentEventsTable';
import Pagination from '../Pagination/Pagination';
import Search from '../Search/Search';
import Loading from '../UI/Loading';
import { API_TEST } from '../../utils/helpers';
import AuthContext from '../../store/auth-context';

const UsersCEList = () => {
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  const { user_id } = useParams();
  const [events, setEvents] = useState(null);
  const [filteredEvents, setFilteredEvents] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [isLoading, setIsLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [pagesLength, setPagesLength] = useState(1);

  const eventsUserSortHandler = () => {
    const eventsData = filteredEvents;
    if(sortDirection === "desc") {
      setSortDirection("asc");
      setFilteredEvents(eventsData.sort(function(a, b){ return b.name - a.name }));
    } else {
      setSortDirection("desc");
      setFilteredEvents(eventsData.sort(function(a, b){ return a.name - b.name }));
    }
  }

  const eventsDateSortHandler = () => {
    const eventsData = filteredEvents;
    if(sortDirection === "desc") {
      setSortDirection("asc");
      setFilteredEvents(eventsData.sort(function(a, b){ return new Date(b.createdOn) - new Date(a.createdOn) }));
    } else {
      setSortDirection("desc");
      setFilteredEvents(eventsData.sort(function(a, b){ return new Date(a.createdOn) - new Date(b.createdOn) }));
    }
  }

  const pageChangeHandler = (newPage) => {
    setEvents(null);
    fetchConsentEvents();
    setPage(newPage);
  }

  const searchHandler = (searchString) => {
    setSearchQuery(searchString);
    const eventsList = events;
    console.log(searchQuery);
    const filteredEventsList = eventsList.filter((ev) => ev.id === searchString || ev.phoneNumber.includes(searchString));
    setFilteredEvents(filteredEventsList);
  }

  const fetchConsentEvents = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(API_TEST + `ConsentEvents/users/${user_id}`, { headers: {Authorization: `Bearer ${token}`} });

      if(response.status === 401) {
        fetch(API_TEST + 'Admin/refresh-access-token', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Cache': 'no-cache',
            'Authorization': `Bearer ${token}`
          },
          credentials: 'include',
          body: JSON.stringify({})
        }).then((resp) => {
          if(resp.status === 400) {
            authCtx.onLogOut();
            return;
          }
          return resp.json();
        }).then(data => {
          authCtx.setNewToken(data.accessToken);
        });
        return;
      } else if(response.status === 400) {
        authCtx.onLogOut();
        return;
      }

      if (!response.ok) {
        throw new Error('Something went wrong!');
      }
 
      const resData = await response.json();

      if (!resData) {
        throw new Error('There is no events!');
      }

      setEvents(resData);
      setFilteredEvents(resData);
      setPagesLength(1);
    } catch (error) {
      console.log(error.message);
    }
    setIsLoading(false);
  }, [user_id, authCtx, token]);

  useEffect(() => {
    fetchConsentEvents();
  }, [fetchConsentEvents]);

  let content = (
    <>
      <ConsentEventsTable events={filteredEvents} onEventsUserSort={eventsUserSortHandler} onEventsDateSort={eventsDateSortHandler} />
      <Pagination page={page} pagesLength={pagesLength} onPageChange={pageChangeHandler} />
    </>
  );

  if (isLoading) {
    content = <Loading />;
  }

  return (
    <div className="container container-table">
      <div className="container-header">
        <ContainerTitle
          title={"Users"}
          parentPageLink={"/users"}
          childPageTitle={"Single User's CE list"}
        />
        <Search onSearch={searchHandler} placeholder={"Search by ID, user name..."} />
      </div>
      <div className="container-content">{content}</div>
    </div>
  );
}

export default UsersCEList;