import React, { useState } from "react";

import logo from "../../assets/images/logo.svg";
import ErrorBox from "../UI/ErrorBox";
import SignInForm from "./SignInForm";
import TwoFactorAuth from "./TwoFactorAuth";

const SignIn = (props) => {
  const [showAuth, setShowAuth] = useState(false);
  const [adminRememberMe, setAdminRememberMe] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);

  const showAuthHandler = (res) => {
    setShowAuth(true);
    setResponse(res);
  };

  const showErrorHandler = (errorMsg) => {
    setError(errorMsg);
  };

  const setAdminRememberMeHandler = (toRemember) => {
    setAdminRememberMe(toRemember);
  };

  return (
    <div className="sign-in">
      <div className="sign-in-box">
        <img src={logo} alt="Peach Protector logo" />
        {error && <ErrorBox error={error} />}
        {!showAuth && (
          <SignInForm
            setToken={props.setToken}
            showErrorHandler={showErrorHandler}
            showAuthHandler={showAuthHandler}
            setAdminRememberMeHandler={setAdminRememberMeHandler}
          />
        )}
        {showAuth && (
          <TwoFactorAuth
            response={response}
            showErrorHandler={showErrorHandler}
            adminRememberMe={adminRememberMe}
          />
        )}
      </div>
    </div>
  );
};

export default SignIn;
