import React, { useContext, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import { API_TEST, getDateString, getTimeString } from "../../utils/helpers";
import Modal from '../UI/Modal';

import iconDelete from '../../assets/images/icon-account-delete.svg';
import iconPeaches from '../../assets/images/icon-peaches.svg';
import iconPremium from '../../assets/images/icon-premium.svg';
import iconCreated from '../../assets/images/icon-event-created.svg';
import iconRevoked from '../../assets/images/icon-event-revoked.svg';
import iconRetrieved from '../../assets/images/icon-event-retrieved.svg';
import Loading from '../UI/Loading';
import NotificationContext from '../../store/notification-context';
import SavedPassphraseLog from './SavedPassphraseLog';
import AuthContext from '../../store/auth-context';

const UserSingleTable = ({ user, userId }) => {
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  const navigate = useNavigate();
  const notificationCtx = useContext(NotificationContext);

  let subscriptionType = null;
  if(user.subscriptionType === 1) { subscriptionType = "standard"; }
  if(user.subscriptionType === 2) { subscriptionType = "premium"; }

  const [deletingUser, setDeletingUser] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const openDeleteDialog = () => {
    setDeletingUser(true);
  }

  const closeDeleteDialog = () => {
    setDeletingUser(false);
  }

  const deleteUserHandler = () => {
    setDeletingUser(false);
    setIsDeleting(true);

    try {
      fetch(API_TEST + `Users/${userId}`, {
        method: 'DELETE',
        headers: {Authorization: `Bearer ${token}`}
      }).then((data) => {
        if(data.status === 401) {
          fetch(API_TEST + 'Admin/refresh-access-token', {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
              'Cookie': authCtx.refreshToken
            },
            body: JSON.stringify({})
          }).then((resp) => {
            if(resp.status === 400) {
              authCtx.onLogOut();
              return;
            }
            return resp.json();
          }).then(data => {
            authCtx.setNewToken(data.accessToken);
            deleteUserHandler();
          });
          return;
        } else if(data.status === 400) {
          authCtx.onLogOut();
          return;
        }

        if(!data.ok) {
          return;
        }

        notificationCtx.onShowNotification("You have successfully deleted user account.");
        navigate("/users/");
      });
    } catch(err) {
      console.log(err.message);
    }
  }

  return (
    <>
      {
        deletingUser &&
        <Modal onCloseModal={closeDeleteDialog} onConfirm={deleteUserHandler}>
          <h3>Delete account?</h3>
          <p>You have successfully initiated the process to delete <br/><strong>{user.name}</strong>, user account.</p>
          <p>This action is irreversible and all user data will be permanently removed.</p>
        </Modal>
      }
      { isDeleting && <Loading /> }
      {
        !isDeleting &&
        <div className="consent-event-single user-single">
          <div className="consent-event-single-header">
            <div className="consent-event-single-id">
              <p><span>USER</span>{user.name}</p>
            </div>
          </div>
          <div className="consent-event-single-body">
            <div className="consent-event-single-row">

              <div className="consent-event-single-col">
                <div className="consent-event-single-info">
                  <div className="form-field input-field input-field-secondary">
                    <label>Phone Number</label>
                    <div className="input">{user.phoneNumber}</div>
                  </div>
                  <div className="form-field input-field input-field-secondary">
                    <label>Email</label>
                    <div className="input">{user.email}</div>
                  </div>
                  <div className="form-field-row">
                    <div className="form-field input-field input-field-secondary">
                      <label>Date of Birth</label>
                      <div className="input">{user.dateOfBirth}</div>
                    </div>
                  </div>
                </div>
                <div className="user-events-wrap">
                  <div className="form-field input-field input-field-secondary">
                    <label>Consent events</label>
                  </div>
                  <div className="user-events">
                    <div className="user-events-row">
                      <div className="user-events-col">
                        <span>Created</span>
                        <img src={iconCreated} alt="Event created icon" />
                        <p>{user.numerOfCreatedCEs}</p>
                      </div>
                      <div className="user-events-col">
                        <span>Revoked</span>
                        <img src={iconRevoked} alt="Event revoked icon" />
                        <p>{user.numerOfRevokedCEs}</p>
                      </div>
                      <div className="user-events-col">
                        <span>Retrieved</span>
                        <img src={iconRetrieved} alt="Event retrieved icon" />
                        <p>{user.numerOfRetrievedCEs}</p>
                      </div>
                    </div>
                    <div className="user-events-link">
                      <Link to={"/users/" + userId + "/events"}>See all user's CE</Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="consent-event-single-col">
                <div className="retrieval-title">
                  <p>Subscription</p>
                </div>
                <div className="subscription-info">
                  <div className={"subscription-label " + subscriptionType}>{ subscriptionType === "premium" ? <><img src={iconPremium} alt="Premium icon" />PREMIUM</> : <><img src={iconPeaches} alt="Peaches icon" />STANDARD</> }</div>
                  {
                    subscriptionType === "standard" &&
                    <div className="remain-peaches">
                      <div className="form-field input-field input-field-secondary">
                        <label>Remain peaches</label>
                        <div className={user.peachesLeft === 0 ? "input center has-event empty" : "input center has-event"}>
                          {user.peachesLeft}
                        </div>
                      </div>
                      <p>** Each time a new consent event is confirmed, one credit is deducted from the remaining credit count.</p>
                    </div>
                  }
                  {
                    subscriptionType === "premium" &&
                    <div className="form-field-row">
                      <div className="form-field input-field input-field-secondary input-field-secondary-light">
                        <label>Subscribed On</label>
                        <div className="input has-event">
                          { getDateString(new Date(user.subscribedOn)) }<span>{ getTimeString(new Date(user.subscribedOn)) }</span><u>UTC</u>
                        </div>
                      </div>
                      <div className="form-field input-field input-field-secondary input-field-secondary-light">
                        <label>Expires On</label>
                        <div className="input has-event">
                          { getDateString(new Date(user.expiresOn)) }<span>{ getTimeString(new Date(user.expiresOn)) }</span><u>UTC</u>
                        </div>
                      </div>
                    </div>
                  }
                </div>
                <div className="form-field input-field input-field-secondary">
                  <label>Account</label>
                </div>
                <div className="user-account">
                  <div className="user-account-row">
                    <div className="user-account-col">
                    <div className="form-field input-field input-field-secondary">
                      <label>Registered on</label>
                      <div className="input">
                        { getDateString(new Date(user.createdAt)) }<span>{ getTimeString(new Date(user.createdAt)) }</span><u>UTC</u>
                      </div>
                    </div>
                    </div>
                    <div className="user-account-col">
                      <div className="user-account-delete">
                        <img src={iconDelete} alt="User delete icon" />
                        <span onClick={openDeleteDialog}>Delete account</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            { user.passphraseLogs.length ? <SavedPassphraseLog passphraseLogs={user.passphraseLogs} /> : ""}
          </div>
        </div>
      }
    </>
  )
}

export default UserSingleTable;