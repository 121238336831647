import React from 'react';
import { useNavigate } from "react-router-dom";
import { getDateString, getTimeString } from "../../utils/helpers";

import iconLocation from '../../assets/images/icon-location.svg';
import iconAccepted from '../../assets/images/icon-accepted.svg';
import iconRejected from '../../assets/images/icon-rejected.svg';
import iconRevoked from '../../assets/images/icon-revoked.svg';
import iconArchived from '../../assets/images/icon-archived.svg';

const ConsentEventsItem = (props) => {
  const navigate = useNavigate();
  const consentEvent = props.consentEvent;

  // Consent Type
  let eventType = null;
  if(consentEvent.consentType === 1) { eventType = "Q"; }
  if(consentEvent.consentType === 2) { eventType = "L"; }
  if(consentEvent.consentType === 3) { eventType = "K"; }

  // Request Retrieval Status
  let eventRequest = null;
  if(consentEvent.requestRetrievalStatus) {
    if(consentEvent.requestRetrievalStatus === 1) { eventRequest = 'requested'; }
    if(consentEvent.requestRetrievalStatus === 2) { eventRequest = 'rejected'; }
    if(consentEvent.requestRetrievalStatus === 3) { eventRequest = 'pending'; }
    if(consentEvent.requestRetrievalStatus === 4) { eventRequest = 'confirmed'; }
    if(consentEvent.requestRetrievalStatus === 5) { eventRequest = 'paid'; }
    if(consentEvent.requestRetrievalStatus === 6) { eventRequest = 'retrieved'; }
  }

  // Status
  let eventStatus = null;
  if(consentEvent.consentEventStatus === 1) { eventStatus = 'accepted'; }
  if(consentEvent.consentEventStatus === 2) { eventStatus = 'denied'; }
  if(consentEvent.consentEventStatus === 3) { eventStatus = 'revoked'; }

  const getConsentTypeName = (consentType) => {
    if (consentType === "q") return 'Ask for Consent';
    if (consentType === "l") return 'Define limits';
    if (consentType === "k") return 'Yes/No/Maybe';
    return 'Unknown';
  };

  const consentEventHandler = (e, consentId) => {
    if(e.target.closest('.consent-events-location') && !e.target.classList.contains('consent-events-location')) return;
    navigate("/consent-events/" + consentId);
  }

  return (
    <tr className={consentEvent.isArchived ? "is-archived" : ""} onClick={(e) => consentEventHandler(e, consentEvent.id)}>
      <td>{ consentEvent.id }</td>
      <td>{ consentEvent.name }</td>
      <td>{ consentEvent.phoneNumber }</td>
      <td className="consent-events-date">
        { getDateString(new Date(consentEvent.createdOn)) } <span>{ getTimeString(new Date(consentEvent.createdOn)) }</span> UTC
      </td>
      <td className="consent-events-location">{ consentEvent.longitude && consentEvent.latitude && <a href={`https://www.google.com/maps/search/?api=1&query=${consentEvent.latitude},${consentEvent.longitude}` } target="_blank" rel="noreferrer" ><img src={iconLocation} alt="Location icon" />Show on map</a>}</td>
      <td>{ consentEvent.sexualPartnerPhoneNumber ? consentEvent.sexualPartnerPhoneNumber : "-" }</td>
      <td className={ eventType.toLowerCase() === "l" ? "consent-events-type type-limits" : "consent-events-type"}>
        <div className="tooltip">
          <span>{ eventType }</span>
          <div className="tooltip-content">{ getConsentTypeName(eventType.toLowerCase()) }</div>
        </div>
      </td>
      <td className="consent-events-status">
        <span className={ eventStatus }>
          { eventStatus }
          { eventStatus === "accepted" && <img src={iconAccepted} alt="Accepted icon" /> }
          { eventStatus === "denied" && <img src={iconRejected} alt="Denied icon" /> }
          { eventStatus === "revoked" && <img src={iconRevoked} alt="Revoked icon" /> }
        </span>
      </td>
      <td className="consent-events-request"><span className={ eventRequest }>{ eventRequest }</span></td>
      {
        consentEvent.isArchived &&
        <td className="consent-events-archived">
          <div className="tooltip left">
            <img src={iconArchived} alt="Archived icon" />
            <div className="tooltip-content">Archived</div>
          </div>
        </td>
      }
    </tr>
  );
}

export default ConsentEventsItem;