import downloadBg from '../../assets/images/download-bg-2.png';
import DownloadProgressImg from '../../assets/images/download-progress-image.png';

const DownloadInProgress = () => {
  return (
    <div className="download">
      <div className="download-container">
        <div className="download-content-wrap">
          <div className="download-content">
            <div className="download-title">
              <h2>Downloading in Progress</h2>
            </div>
            <p>Please keep your computer connected to the internet during the download process.</p>
            <img src={DownloadProgressImg} alt="Download in progress" />
            <p className="text-center"><a href="mailto:support@peachprotector.com" target="_top">Contact Support</a></p>
          </div>
        </div>
        <div className="download-sidebar"><img src={downloadBg} alt="Download background" /></div>
      </div>
    </div>
  );
}

export default DownloadInProgress;