import React, { useState, useEffect, useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import ContainerTitle from "../UI/ContainerTitle";
import Loading from "../UI/Loading";
import UserSingleTable from "./UserSingleTable";
import { API_TEST } from "../../utils/helpers";
import NoResults from "../UI/NoResults";
import AuthContext from "../../store/auth-context";

const UserSingle = () => {
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  const { user_id } = useParams();

  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchUser = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(API_TEST + `Users/${user_id}/admin`, { headers: {Authorization: `Bearer ${token}`} });

      if(response.status === 401) {
        fetch(API_TEST + 'Admin/refresh-access-token', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Cache': 'no-cache',
            'Authorization': `Bearer ${token}`
          },
          credentials: 'include',
          body: JSON.stringify({})
        }).then((resp) => {
          if(resp.status === 400) {
            authCtx.onLogOut();
            return;
          }
          return resp.json();
        }).then(data => {
          authCtx.setNewToken(data.accessToken);
        });
        return;
      } else if(response.status === 400) {
        authCtx.onLogOut();
        return;
      }

      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const data = await response.json();

      if (!data) {
        throw new Error("There is no user!");
      }
      
      setUser(data);
      console.log(data);
    } catch (error) {
      console.log(error.message);
    }
    setIsLoading(false);
  }, [user_id, authCtx, token]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  let content = <Loading />;

  if (user) {
    content = <UserSingleTable user={user} userId={user_id} />;
  }

  if (!user) {
    content = (
      <table>
        <tbody>
          <NoResults />
        </tbody>
      </table>
    );
  }

  if (isLoading) {
    content = <Loading />;
  }

  return (
    <div className="container container-table">
      <div className="container-header">
        <ContainerTitle
          title={"Users"}
          parentPageLink={"/users"}
          childPageTitle={"Single User"}
        />
      </div>
      <div className="container-content">{content}</div>
    </div>
  );
};

export default UserSingle;
