import React, { useState, useContext } from "react";
import ContainerTitle from "../UI/ContainerTitle";
import useForm from "../../hooks/use-form";
import AuthModal from "../UI/AuthModal";
import NotificationContext from "../../store/notification-context";
import iconEye from "../../assets/images/icon-eye.svg";
import iconEyeVisible from "../../assets/images/icon-eye-visible.svg";
import { API_TEST, getAdminData, getAdminId } from "../../utils/helpers";
import AuthContext from "../../store/auth-context";

const Settings = () => {
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  const refreshToken = authCtx.refreshToken;

  const notificationCtx = useContext(NotificationContext);
  const adminData = getAdminData();
  const adminId = getAdminId();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const showNewPasswordHandler = () => {
    setShowNewPassword((prevVal) => !prevVal);
  };

  const showConfirmPasswordHandler = () => {
    setShowConfirmPassword((prevVal) => !prevVal);
  };

  const [error, setError] = useState("");

  const currentPasswordHandler = (e) => {
    setCurrentPassword(e.target.value);
  };

  const newPasswordHandler = (e) => {
    setNewPassword(e.target.value);
  };

  const confirmPasswordHandler = (e) => {
    setConfirmPassword(e.target.value);
  };

  const resetPasswordFields = () => {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setError("");
  };

  const abortPasswordChange = () => {
    resetPasswordFields();
  };

  const changePasswordHandler = (e) => {
    e.preventDefault();

    if (!currentPassword || !newPassword || !confirmPassword) {
      setError("All field required.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      const passData = {
        id: adminId,
        oldPassword: currentPassword,
        newPassword: newPassword
      }
      fetch(API_TEST + 'Admin/change-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json-patch+json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(passData)
      }).then((res) => {
        if(res.status === 401) {
          fetch(API_TEST + 'Admin/refresh-access-token', {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
              'Cookie': refreshToken
            },
            body: JSON.stringify({})
          }).then((resp) => {
            if(resp.status === 400) {
              authCtx.onLogOut();
              return;
            }
            return resp.json();
          }).then(data => {
            authCtx.setNewToken(data.accessToken);
            changePasswordHandler(e);
          });
        } else if(res.status === 400) {
          authCtx.onLogOut();
          return;
        }
        
        if(res.ok) {
          notificationCtx.onShowNotification("You have successfully update your password.");
          resetPasswordFields();
          return { Succeeded: true }
        }

        return res.json();
      }).then((data) => {
        if(!data.Succeeded) {
          notificationCtx.onShowNotification(data.Message, "error");
        }
      });
    } catch(err) {
      console.log(err.message);
    }
  };

  // 2FA
  const [adminAuth, setAdminAuth] = useState(adminData ? adminData.auth : false);
  const [authActive, setAuthActive] = useState(adminData ? adminData.auth : false);
  const [isAuth, setIsAuth] = useState(false);
  const {
    value: phone,
    isValid: phoneIsValid,
    hasError: phoneHasError,
    valueChangeHandler: phoneChangeHandler,
    inputBlurHandler: phoneBlurHandler,
  } = useForm((value) => value.match("[0-9]{10}"));

  const authActiveHandler = (e) => {
    if(adminAuth) {
      try {
        fetch(API_TEST + `Admin/${adminId}/disable-2fa`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json-patch+json',
            'Authorization': `Bearer ${token}`
          }
        }).then((res) => {
          if(res.status === 401) {
            fetch(API_TEST + 'Admin/refresh-access-token', {
              method: 'POST',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Cookie': authCtx.refreshToken
              },
              body: JSON.stringify({})
            }).then((resp) => {
              if(resp.status === 400) {
                authCtx.onLogOut();
                return;
              }
              return resp.json();
            }).then(data => {
              authCtx.setNewToken(data.accessToken);
              authActiveHandler(e);
            });
            return;
          } else if(res.status === 400) {
            authCtx.onLogOut();
            return;
          }

          if(res.ok) {
            setIsAuth(true);
            return { Succeeded: true }
          }
          return res.json();
        }).then((data) => {
          if(!data.Succeeded) {
            notificationCtx.onShowNotification(data.Message, "error");
            return;
          }
          setIsAuth(true);
        });
      } catch(err) {
        console.log(err.message);
      }
    }
    setAuthActive(e.target.checked);
  };

  const openPhoneAuth = (e) => {
    e.preventDefault();

    setIsAuth(false);
    if (!phoneIsValid) {
      return;
    }

    try {
      fetch(API_TEST + `Admin/${adminId}/enable-2fa?phoneNumber=${phone.replace('+', '%2B')}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json-patch+json',
          'Authorization': `Bearer ${token}`
        }
      }).then((res) => {
        if(res.status === 401) {
          fetch(API_TEST + 'Admin/refresh-access-token', {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
              'Cookie': authCtx.refreshToken
            },
            body: JSON.stringify({})
          }).then((resp) => {
            if(resp.status === 400) {
              authCtx.onLogOut();
              return;
            }
            return resp.json();
          }).then(data => {
            authCtx.setNewToken(data.accessToken);
            openPhoneAuth(e);
          });
          return;
        } else if(res.status === 400) {
          authCtx.onLogOut();
          return;
        }

        if(res.ok) {
          setIsAuth(true);
          return { Succeeded: true }
        }
        return res.json();
      }).then((data) => {
        if(!data.Succeeded) {
          notificationCtx.onShowNotification(data.Message, "error");
          return;
        }
        setIsAuth(true);
      });
    } catch(err) {
      console.log(err.message);
    }
  };

  const updateLocalStorage = (storageData) => {
    if (sessionStorage.getItem("admin")) {
      sessionStorage.removeItem("admin");
      sessionStorage.setItem("admin", JSON.stringify(storageData));
    }
    if (localStorage.getItem("admin")) {
      localStorage.removeItem("admin");
      localStorage.setItem("admin", JSON.stringify(storageData));
    }
  }

  const addPhoneAuth = () => {
    setIsAuth(false);
    setAdminAuth(true);
    notificationCtx.onShowNotification(
      "You have successfully activated two-factor authentication."
    );

    let storageAdmin = adminData;
    storageAdmin.auth = true;
    updateLocalStorage(storageAdmin);
  };

  const removePhoneAuth = () => {
    setIsAuth(false);
    setAdminAuth(false);
    notificationCtx.onShowNotification(
      "You have successfully deactivated two-factor authentication."
    );

    let storageAdmin = adminData;
    storageAdmin.auth = false;
    updateLocalStorage(storageAdmin);
  };

  const closeAuthModalHandler = () => {
    setIsAuth(false);
  };

  return (
    <>
      {isAuth && (
        <AuthModal
          onSubmit={addPhoneAuth}
          onDisable={removePhoneAuth}
          onCloseAuthModal={closeAuthModalHandler}
          adminId={adminId}
          adminAuth={adminAuth}
          authModalType="2fa"
          phoneNumber={phone}
        />
      )}
      <div className="container container-settings">
        <div className="container-header">
          <ContainerTitle
            title={"Settings"}
            pageLabel={"Administrator's profile"}
          />
        </div>
        <div className="container-content">
          <div className="consent-event-single settings">
            <div className="consent-event-single-body">
              <div className="consent-event-single-row">
                <div className="consent-event-single-col">
                  <div className="settings-info">
                    <div className="form-field input-field input-field-secondary">
                      <label>Email</label>
                      <div className="input">
                        {adminData ? adminData.email : "administrator@gmail.com"}
                      </div>
                    </div>
                  </div>
                  <div className="settings-password">
                    <div className="settings-password-label">
                      <p>Update password</p>
                    </div>
                    <form onSubmit={changePasswordHandler}>
                      <div className="form-field input-field input-field-secondary">
                        <label>Password</label>
                        <input
                          type="text"
                          name="current-password"
                          id="current-password"
                          placeholder="Enter current password"
                          value={currentPassword}
                          onInput={currentPasswordHandler}
                        />
                      </div>
                      <div className="form-field input-field input-field-secondary">
                        <label htmlFor="password">New Password</label>
                        <div className="password-field">
                          <input
                            type={showNewPassword ? "text" : "password"}
                            name="new-password"
                            id="new-password"
                            value={newPassword}
                            onInput={newPasswordHandler}
                            placeholder="Enter new password"
                          />
                          {newPassword && (
                            <span
                              className={
                                showNewPassword
                                  ? "show-password active"
                                  : "show-password"
                              }
                              onClick={showNewPasswordHandler}
                            >
                              <img
                                src={showNewPassword ? iconEyeVisible : iconEye}
                                alt="Eye icon"
                              />
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="form-field input-field input-field-secondary">
                        <label htmlFor="password">Confirm Password</label>
                        <div className="password-field">
                          <input
                            type={showConfirmPassword ? "text" : "password"}
                            name="confirm-password"
                            id="confirm-password"
                            value={confirmPassword}
                            onInput={confirmPasswordHandler}
                            placeholder="Retype new password"
                          />
                          {confirmPassword && (
                            <span
                              className={
                                showConfirmPassword
                                  ? "show-password active"
                                  : "show-password"
                              }
                              onClick={showConfirmPasswordHandler}
                            >
                              <img
                                src={
                                  showConfirmPassword ? iconEyeVisible : iconEye
                                }
                                alt="Eye icon"
                              />
                            </span>
                          )}
                        </div>
                        {error && <p className="error-text">{error}</p>}
                      </div>
                      <div
                        className="settings-password-buttons"
                        style={{ opacity: currentPassword ? 1 : 0 }}
                      >
                        <span
                          className="btn btn-outline"
                          onClick={abortPasswordChange}
                        >
                          Cancel
                        </span>
                        <button
                          type="submit"
                          className="btn btn-primary btn-small"
                        >
                          <span>CHANGE PASSWORD</span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="consent-event-single-col">
                  <div className="settings-auth">
                    <div className="settings-auth-cb">
                      <p>Two-Factor Authentication</p>
                      <div className="auth-checkbox">
                        <label className="switch" htmlFor="auth-cb">
                          <input
                            type="checkbox"
                            id="auth-cb"
                            checked={authActive}
                            onChange={authActiveHandler}
                          />
                          <div className="slider"></div>
                        </label>
                      </div>
                    </div>
                    <div className="settings-auth-content">
                      <p>
                        Enhance your account security by activating two-factor
                        authentication. Once enabled, you'll be prompted to
                        input your phone number to receive authentication codes
                        on your device.
                      </p>
                    </div>
                    {
                      !adminAuth &&
                      <form
                        className="settings-auth-form"
                        style={{ opacity: authActive ? 1 : 0 }}
                        onSubmit={openPhoneAuth}
                      >
                        <div className="form-field input-field input-field-secondary">
                          <label>Phone Number</label>
                          <input
                            type="text"
                            name="phone"
                            id="phone"
                            placeholder="Enter phone number"
                            value={phone}
                            onChange={phoneChangeHandler}
                            onBlur={phoneBlurHandler}
                          />
                          {phoneHasError && (
                            <p className="error-text">Phone is not valid</p>
                          )}
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary btn-small"
                          disabled={phone ? false : true}
                        >
                          <span>SEND CODE</span>
                        </button>
                      </form>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
