import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import AuthContext from '../../store/auth-context';
import logoWarning from '../../assets/images/logo-warning.svg';
import { useNavigate } from 'react-router-dom';

const ExpiredModalBackdrop = () => {
  return (
    <div className="auth-modal-overlay"></div>
  )
}

const ExpiredModalOverlay = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  const modalConfirmHandler = () => {
    navigate('/');
    authCtx.onLogOut();
  };

  return (
    <div className="modal modal-expired">
      <div className="modal-content">
        <img src={logoWarning} alt="Peach Protector logo" />

        <h3>SESSION EXPIRED</h3>
        <p>Unfortunately, your session has expired for security reasons.</p>
        <p>Please sign in again to continue accessing the services.</p>
        
        <div className="modal-buttons">
          <button className="btn btn-primary btn-small" onClick={modalConfirmHandler}><span>PROCEED WITH SIGN IN</span></button>
        </div>
      </div>
    </div>
  );
};

const ExpiredModal = (props) => {
  return (
    <>
      {ReactDOM.createPortal(
        <ExpiredModalBackdrop />,
        document.getElementById('overlay-root')
      )}
      {ReactDOM.createPortal(
        <ExpiredModalOverlay />,
        document.getElementById('overlay-root')
      )}
    </>
  )
}

export default ExpiredModal;