import React, { useState } from 'react';

const NotificationContext = React.createContext({
  showNotification: false,
  message: "",
  type: "",
  onShowNotification: (text) => {},
  onHideNotification: () => {},
});

export const NotificationContextProvider = (props) => {
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");

  const hideNotificationHandler = () => {
    setShowNotification(false);
    setMessage("");
    setType("");
  };

  const showNotificationHandler = (text, textType = "success") => {
    setMessage(text);
    setType(textType);
    setShowNotification(true);
    setTimeout(() => {
      hideNotificationHandler();
    }, 3000);
  };

  return (
    <NotificationContext.Provider
      value={{
        showNotification,
        message,
        type,
        onShowNotification: showNotificationHandler,
        onHideNotification: hideNotificationHandler,
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
};

export default NotificationContext;