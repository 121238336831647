import { useState } from 'react';
import iconArrow from '../../assets/images/icon-arrow-down.svg';

const SavedPassphraseLog = ({ passphraseLogs }) => {
  const [isActive, setIsActive] = useState(false);

  const openPassphraseLogHandler = () => {
    if(!passphraseLogs > 1) { return; }
    setIsActive(prevState => !prevState);
  }

  return (
    <div className={passphraseLogs.length > 1 ? "passphrase-log-wrap" : "passphrase-log-wrap single"}>
      <p>Saved Passphrase Log</p>
      <div className={isActive ? "passphrase-log active" : "passphrase-log"} onClick={openPassphraseLogHandler}>
        <div className="passphrase-log-content">
          <p>LAST SAVED ON<span className="passphrase-log-date">{ passphraseLogs[0].sentOn }<span>TO</span><span class="passphrase-log-email">{passphraseLogs[0].emailAddress}</span></span><span className="passphrase-log-arrow"><img src={iconArrow} alt="Down arrow icon" /></span></p>
          {
            passphraseLogs.length > 1 &&
            <div className="passphrase-log-list">
              <p>PREVIOUSLY SAVED</p>
              <ul>
                {
                  passphraseLogs.map((pp, index) => {
                    if(index === 0) { return ""; }
                    return <li key={"passphrase-" + index}>Passphrase Sent On <span>{pp.sentOn}<span>To</span><span class="passphrase-log-email">{pp.emailAddress}</span></span></li>
                  })
                }
              </ul>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default SavedPassphraseLog;