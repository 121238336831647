import { useState } from "react";
import DownloadInProgress from "./DownloadInProgress";
import DownloadRecording from "./DownloadRecording";
import DownloadExpired from "./DownloadExpired";

const DownloadPage = () => {
  const [downloadState, setDownloadState] = useState("");

  const downloadStateHandler = (stateVal) => {
    setDownloadState(stateVal);
  }

  if(downloadState === "in-progress") {
    return <DownloadInProgress />
  }

  if(downloadState === "expired") {
    return <DownloadExpired />
  }
  
  return <DownloadRecording onDownload={downloadStateHandler} />
}

export default DownloadPage;