import React, { useState, useEffect, useCallback, useContext } from 'react';

import ContainerTitle from '../UI/ContainerTitle';
import ConsentEventsTable from './ConsentEventsTable';
import Pagination from '../Pagination/Pagination';
import Search from '../Search/Search';
import Loading from '../UI/Loading';
import { API_TEST, ITEMS_PER_PAGE } from '../../utils/helpers';
import AuthContext from '../../store/auth-context';

const ConsentEvents = () => {
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  const [events, setEvents] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [sortString, setSortString] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [page, setPage] = useState(1);
  const [pagesLength, setPagesLength] = useState(1);

  const eventsUserSortHandler = () => {
    if(sortString === "Name") {
      if(sortDirection === "asc") {
        setSortDirection("desc");
      } else {
        setSortDirection("asc");
      }
    } else {
      setSortDirection("asc");
    }
    setSortString("Name");
  }

  const eventsDateSortHandler = () => {
    if(sortString === "CreatedOn") {
      if(sortDirection === "asc") {
        setSortDirection("desc");
      } else {
        setSortDirection("asc");
      }
    } else {
      setSortDirection("asc");
    }
    setSortString("CreatedOn");
  }

  const pageChangeHandler = (newPage) => {
    setPage(newPage);
  }

  const searchHandler = (searchString) => {
    setPage(1);
    setSearchQuery(searchString);
  }

  const fetchConsentEvents = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(API_TEST + `ConsentEvents/admin?PaginationFilter.PageNumber=${page}&PaginationFilter.PageSize=${ITEMS_PER_PAGE}&PaginationFilter.FilterText=${searchQuery}&PaginationFilter.SortColumnName=${sortString}&PaginationFilter.SortColumnDirection=${sortDirection}`,
      { headers: {Authorization: `Bearer ${token}`} });

      if(response.status === 401) {
        fetch(API_TEST + 'Admin/refresh-access-token', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Cache': 'no-cache',
            'Authorization': `Bearer ${token}`
          },
          credentials: 'include',
          body: JSON.stringify({})
        }).then((resp) => {
          if(!resp.ok) {
            return {};
          }
          return resp.json();
        }).then(data => {
          if(data.accessToken) {
            authCtx.setNewToken(data.accessToken);
          } else {
            authCtx.onLogOut();
          }
        });
        return;
      } else if(response.status === 400) {
        authCtx.onLogOut();
        return;
      }
      
      if (!response.ok) {
        throw new Error('Something went wrong!');
      }
 
      const resData = await response.json();

      if (!resData) {
        throw new Error('There is no events!');
      }

      setEvents(resData.data);
      setPagesLength(resData.totalPages || 1);
    } catch (error) {
      console.log(error.message);
    }
    setIsLoading(false);
  }, [page, searchQuery, sortString, sortDirection, authCtx, token]);

  useEffect(() => {
    fetchConsentEvents();
  }, [fetchConsentEvents]);

  let content = (
    <>
      <ConsentEventsTable events={events} onEventsUserSort={eventsUserSortHandler} onEventsDateSort={eventsDateSortHandler} />
      <Pagination page={page} pagesLength={pagesLength} onPageChange={pageChangeHandler} />
    </>
  );

  if (isLoading) {
    content = <Loading />;
  }

  return (
    <div className="container container-table">
      <div className="container-header">
        <ContainerTitle title={"Consent Events"} />
        <Search onSearch={searchHandler} placeholder={"Search by ID, user name..."} />
      </div>
      <div className="container-content">{content}</div>
    </div>
  );
}

export default ConsentEvents;