import React, { useContext, useState } from 'react';
import ReactDOM from 'react-dom';

import logo from '../../assets/images/logo.svg';
import iconPhone from '../../assets/images/icon-phone.svg';
import ErrorBox from '../UI/ErrorBox';
import { API_TEST } from '../../utils/helpers';
import AuthContext from '../../store/auth-context';

const AuthModalBackdrop = (props) => {
  return (
    <div className="auth-modal-overlay"></div>
  )
}

const AuthModalOverlay = (props) => {
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  const [authCode, setAuthCode] = useState("");
  const [authError, setAuthError] = useState("");
  const [authCodeExpired, setAuthCodeExpired] = useState(false);
  const [isResending, setIsResending] = useState(false);

  const authCodeChangeHandler = (e) => {
    setAuthCode(e.target.value);
  }

  const confirmAuthCodeHandler = (e) => {
    e.preventDefault();

    if(!authCode) {
      setAuthError("Please enter code");
      return;
    }

    if(props.authModalType === "2fa") {
      let authType = "enable";
      if(props.adminAuth) { authType = "disable"; }

      try {
        fetch(API_TEST + `Admin/${props.adminId}/${authType}-2fa/confirm?code=${authCode}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json-patch+json',
            'Authorization': `Bearer ${token}`
          }
        }).then((res) => {
          if(res.status === 401) {
            fetch(API_TEST + 'Admin/refresh-access-token', {
              method: 'POST',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Cookie': authCtx.refreshToken
              },
              body: JSON.stringify({})
            }).then((resp) => {
              if(resp.status === 400) {
                authCtx.onLogOut();
                return;
              }
              return resp.json();
            }).then(data => {
              authCtx.setNewToken(data.accessToken);
              confirmAuthCodeHandler(e);
            });
            return;
          } else if(res.status === 400) {
            authCtx.onLogOut();
            return;
          }

          if(res.ok) {
            return { Succeeded: true }
          }
          return res.json();
        }).then((data) => {
          if(!data.Succeeded) {
            if(data.Message.indexOf('expired')) {
              setAuthCodeExpired(true);
              return;
            }

            setAuthError(data.Message);
            return;
          }

          if(props.adminAuth) {
            props.onDisable();
          } else {
            props.onSubmit();
          }

          props.onCloseAuthModal();
        });
      } catch(err) {
        console.log(err.message);
      }
      
      return;
    }

    if(props.authModalType === "ce") {
      try {
        fetch(API_TEST + `Admin/${props.adminId}/2fa/confirm?code=${authCode}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json-patch+json',
            'Authorization': `Bearer ${token}`
          }
        }).then((res) => {
          if(res.status === 401) {
            fetch(API_TEST + 'Admin/refresh-access-token', {
              method: 'POST',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Cookie': authCtx.refreshToken
              },
              body: JSON.stringify({})
            }).then((resp) => {
              if(resp.status === 400) {
                authCtx.onLogOut();
                return;
              }
              return resp.json();
            }).then(data => {
              authCtx.setNewToken(data.accessToken);
              confirmAuthCodeHandler(e);
            });
            return;
          } else if(res.status === 400) {
            authCtx.onLogOut();
            return;
          }
          
          if(res.ok) {
            return { Succeeded: true }
          }
          return res.json();
        }).then((data) => {
          if(!data.Succeeded) {
            setAuthError(data.Message);
            return;
          }
  
          props.onSubmit();
          props.onCloseAuthModal();
        });
      } catch(err) {
        console.log(err.message);
      }

      return;
    }
  }

  const resendAuthCode = () => {
    setIsResending(true);
    setAuthCodeExpired(false);
    setAuthError("");

    try {
      fetch(API_TEST + `Admin/resend-verification-code?adminId=${props.adminId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json-patch+json',
          'Authorization': `Bearer ${token}`
        }
      }).then((res) => {
        if(res.ok) {
          return { Succeeded: true }
        }
        return res.json();
      }).then((data) => {
        if(!data.Succeeded) {
          setAuthError(data.Message);
          return;
        }
      });
    } catch(err) {
      console.log(err.message);
    }

    setIsResending(false);
  }

  return (
    <div className="auth-modal">
      <span className="auth-modal-close" onClick={props.onCloseAuthModal}><i class="fa-solid fa-xmark"></i></span>

      <img src={logo} alt="Peach Protector logo" />
      {authError && <ErrorBox error={authError} />}
      <h2>Two-Factor Authentication</h2>

      <div className="auth">
        <div className="auth-icon">
          <img src={iconPhone} alt="Phone icon" />
        </div>
        <div className="auth-content">
          <p>To activate this extra security level, enter the 6-digit code we sent to the phone number you provided. <br/>This is a one-time action.</p>
        </div>
      </div>

      <form className="auth-form" onSubmit={confirmAuthCodeHandler}>
        <div className={ authCodeExpired ? "form-field input-field form-field-error" : "form-field input-field"}>
          <label htmlFor="auth-code">SMS verification Code</label>
          <input type="text" name="auth-code" id="auth-code" onChange={authCodeChangeHandler} value={authCode} placeholder="Enter code" />
          { authCodeExpired && <p className="error-text">The verification code expired. Please try again.</p> }
        </div>
        <div className="form-submit">
          <button className="btn btn-primary" disabled={authCode ? false : true}><span>Verify</span></button>
          <span className="btn-link" onClick={resendAuthCode} style={{pointerEvents: isResending ? "none" : "all"}}>Resend Code</span>
        </div>
      </form>
    </div>
  );
};

const AuthModal = (props) => {
  return (
    <>
      {ReactDOM.createPortal(
        <AuthModalBackdrop />,
        document.getElementById('overlay-root')
      )}
      {ReactDOM.createPortal(
        <AuthModalOverlay onSubmit={props.onSubmit} onDisable={props.onDisable} onCloseAuthModal={props.onCloseAuthModal} adminId={props.adminId} adminAuth={props.adminAuth} authModalType={props.authModalType} phoneNumber={props.phoneNumber} />,
        document.getElementById('overlay-root')
      )}
    </>
  )
}

export default AuthModal;