import React from 'react'

const ErrorBox = (props) => {
  return (
    <div className="error-box">
      <p>{props.error}</p>
    </div>
  );
}

export default ErrorBox;