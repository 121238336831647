import React from 'react';
import { NavLink } from "react-router-dom";

import logo from '../../assets/images/logo-short.svg';
import iconCE from '../../assets/images/icon-ce.svg';
import iconUsers from '../../assets/images/icon-user.svg';
import iconSettings from '../../assets/images/icon-settings.svg';
import iconLogout from '../../assets/images/icon-logout.svg';

const Navigation = (props) => {  
  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <NavLink to="/" className="nav-link">
          <img src={logo} alt="Peach Protector logo" />
        </NavLink>
      </div>
      <ul className="navbar-nav">
        <li className="nav-item">
          <NavLink to="/" className="nav-link">
            <img src={iconCE} alt="Consent Events List icon" />
            <span>Consent Events</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/users" className="nav-link">
            <img src={iconUsers} alt="Users icon" />
            <span>Users</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/settings" className="nav-link">
            <img src={iconSettings} alt="Settings icon" />
            <span>Settings</span>
          </NavLink>
        </li>
      </ul>
      <div className="navbar-logout">
        <button onClick={props.signOut}>
          <img src={iconLogout} alt="Log out icon" />
          <span>Log Out</span>
        </button>
      </div>
    </nav>
  )
}

export default Navigation;